import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { Box, Typography } from "@mui/material";

interface Props {
  url: string;
  label?: string;
  size?: number;
  labelFontSize?: string;
}
const OneAppQr = ({ url, label, size = 80, labelFontSize = "0.8rem" }: Props): JSX.Element => (
  <Box className="qrCodeContainer">
    <Box className="qrCode">
      <QRCodeSVG size={size} value={url} />
    </Box>
    {label && (
      <Box component="span" textAlign="left" pt={1}>
        <Typography sx={{ maxWidth: "130px", fontSize: labelFontSize }}>{label}</Typography>
      </Box>
    )}
  </Box>
);

export default OneAppQr;
