import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { match as MatchProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { omit } from "lodash";
import { round } from "mathjs";
import produce from "immer";
import Filters, { FilterProps, vendorTypeListFilters } from "../../../Reports/Filters";
import * as calFns from "../../../../components/Calendar/functions/calendarFunctions";
import List from "../../../../components/List";
import { EmptyView, ListActions, Menu, MenuItem } from "../../../../components/OList";
import {
  CustomerVendorReportProps,
  SubLedgerMapping,
  VendorType,
  VoucherCodes,
  VoucherInterface
} from "../../../../interfaces/Accounts";
import { getCustomerVendorReport } from "../../../../api/reports";
import "../styles.scss";
import * as NotificationActions from "../../../../actions/notification";
import classNames from "../../../../helpers/classNames";
import ReportDetailView from "../ReportDetailView";
import {
  getContraVoucherById,
  getPaymentVoucherById,
  getReceiptVoucherById,
  getSalesVoucherById,
  getStockTransactionVoucherById
} from "../../../../api/accounts";
import VoucherItemView from "../../VoucherItemView";
import { RootState } from "../../../../store";
import { getCurrentSubscription } from "../../../../slices/subscriptionSlice";
import { tl } from "../../../../components/translate";
import { downloadExcel } from "../../../../helpers/files";
import { formatDataForExcel, formatHeaderForExcel } from "../helper";
import { rupeeDisplay } from "../../../../helpers/rupee";
import { IdSelector } from "../../../Reports/EmployeeMultiSelect";

export enum ReportType {
  VENDOR_REPORT = "vendorReport",
  CUSTOMER_REPORT = "customerReport"
}

const docColumns = () => [
  "Date",
  "Source Ledger Name",
  "Opening Balance",
  "Running Balance",
  "Destination Ledger",
  "Voucher Number",
  "Sub Ledger",
  "Voucher Code",
  "Credit",
  "Debit"
];

const docRowProcessor = ({
  date,
  sourceLedgerName,
  openingBalance,
  runningBalance,
  destinationLedgerName,
  voucherNumber,
  subLedgerName,
  voucherCode,
  credit,
  debit,
  // eslint-disable-next-line camelcase
  __meta__row_type
}) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    calFns.bsFullDate(date) || "",
    sourceLedgerName || "",
    openingBalance || "",
    runningBalance || "",
    destinationLedgerName || "",
    voucherNumber || "",
    subLedgerName || "",
    voucherCode || "",
    credit || "",
    debit || ""
  ];
};

export default function CustomerVendorReport({ match }: { match: MatchProps }): JSX.Element {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [voucherDetail, setVoucherDetail] = React.useState<VoucherInterface | null>(null);

  const reportType = match.path.split("/").pop();
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const currentSubscription = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription
  );

  React.useEffect(() => {
    if (!currentSubscription) {
      dispatch(getCurrentSubscription(rcId));
    }
  }, [currentSubscription, dispatch, rcId]);
  const [filters, setFilters] = React.useState<FilterProps>({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    subLedgerType:
      reportType === ReportType.CUSTOMER_REPORT
        ? SubLedgerMapping.CUSTOMER
        : SubLedgerMapping.VENDOR,
    ...(reportType === ReportType.CUSTOMER_REPORT && { clientIds: [] }),
    ...(reportType === ReportType.VENDOR_REPORT && {
      vendorType: VendorType.SUPPLIER,
      supplierIds: []
    })
  });
  const [reportData, setReportData] = React.useState<CustomerVendorReportProps[]>([]);

  const filterHandler = (newFilters: FilterProps) => {
    if (!newFilters.vendorType) {
      setFilters(newFilters);
      return;
    }
    const reportFilters = produce(omit(newFilters, vendorTypeListFilters), (draft) => {
      switch (draft.vendorType) {
        case VendorType.EMPLOYEE:
          draft.employeeIds = newFilters.employeeIds || [];
          break;
        case VendorType.REFERRER:
          draft.referrersName = newFilters.referrersName || [];
          break;
        case VendorType.SERVICE_PROVIDER:
          draft.serviceProviderIds = newFilters.serviceProviderIds || [];
          break;
        case VendorType.SUPPLIER:
          draft.supplierIds = newFilters.supplierIds || [];
          break;
        default:
          break;
      }
    }) as FilterProps;
    setFilters(reportFilters as FilterProps);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setIsRequestLoading(true);
        const response = await getCustomerVendorReport(filters);
        setReportData(response.map((res, index) => ({ ...res, id: index })) || []);
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: error?.data?.message || "Something went Wrong!",
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [filters, dispatch]);
  const getVoucher = async ({ voucherCode, voucherId }) => {
    setSelectedRow(null);
    try {
      if ([VoucherCodes.PR, VoucherCodes.DN].includes(voucherCode)) {
        const response = await getStockTransactionVoucherById(voucherCode, voucherId);
        setVoucherDetail(response[0]);
      }
      if (voucherCode === VoucherCodes.PM) {
        setVoucherDetail(await getPaymentVoucherById(voucherId));
      }
      if (voucherCode === VoucherCodes.RC) {
        setVoucherDetail(await getReceiptVoucherById(voucherId));
      }
      if ([VoucherCodes.SL, VoucherCodes.CN].includes(voucherCode)) {
        setVoucherDetail(await getSalesVoucherById(voucherCode, voucherId));
      }
      if (voucherCode === VoucherCodes.CO) {
        setVoucherDetail(await getContraVoucherById(voucherId));
      }
    } catch (error) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error?.data?.message || "Something went Wrong!",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <Box overflow="auto hidden">
      <Box px={4} minWidth={1100}>
        <Filters
          employeeIdSelector={IdSelector.EMPLOYEE}
          forAccountUser
          filters={filters}
          onSetFilters={filterHandler}
          isRequestLoading={isRequestLoading}
        />
      </Box>
      <Box
        className={classNames("customerVendorReportListStyle", {
          vendorReportList: reportType === ReportType.VENDOR_REPORT
        })}
      >
        <List
          automation="customerVendorReportList"
          data={reportData}
          rowHeight={50}
          defaultSortColumn="subLedgerName"
          defaultSortOrder={-1}
          adjustHeightToContents
          hideCreateButton
          withoutSearch
          columns={[
            {
              key: "subLedgerName",
              label: "",
              segmentable: true,
              segmentBy: (row) => `${row.subLedgerName} (ID:${row.subLedgerId})`,
              sortable: true,
              formatter: () => <Typography />
            },
            {
              key: "sourceLedgerName",
              label: "Account Ledger",
              segmentable: true,
              segmentBy: (row) => row.sourceLedgerName,
              sortable: true,
              formatter: ({ sourceLedgerName }) => (
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginRight: "5px"
                  }}
                >
                  {sourceLedgerName}
                </Typography>
              )
            },
            {
              key: "date",
              label: "Date",
              sortable: true,
              sortFunction: (a, b) => (a.created_at > b.created_at ? 1 : -1),
              formatter: ({ date }) => <Typography>{calFns.bsFullDate(date)}</Typography>
            },
            {
              key: "voucherCode",
              label: "Voucher Code",
              sortable: true,
              formatter: ({ voucherCode }) => <Typography>{voucherCode}</Typography>
            },
            {
              key: "voucherNumber",
              label: "Voucher Number",
              sortable: true,
              formatter: (row) => (
                <Link
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (selectedRow) {
                      setSelectedRow(null);
                      return;
                    }
                    getVoucher(row);
                  }}
                >
                  {row.voucherNumber}
                </Link>
              )
            },
            {
              key: "debit",
              label: "Debit",
              formatter: ({ debit }) => (
                <Typography>{rupeeDisplay(round(debit, 2), true)}</Typography>
              )
            },
            {
              key: "credit",
              label: "Credit",
              formatter: ({ credit }) => (
                <Typography>{rupeeDisplay(round(credit, 2), true)}</Typography>
              )
            },
            {
              key: "runningBalance",
              label: "Running Balance",
              formatter: ({ runningBalance }) => (
                <Typography>{rupeeDisplay(round(runningBalance, 2), true)}</Typography>
              )
            },
            {
              key: "referenceNumber",
              label: "Reference Number",
              formatter: ({ referenceNumber }) => <Typography>{referenceNumber}</Typography>
            }
          ].filter(
            (col) => !(col.key === "referenceNumber" && reportType === ReportType.CUSTOMER_REPORT)
          )}
          activeRow={selectedRow && selectedRow.id}
          onRowClick={(item) => {
            if (!selectedRow) {
              setSelectedRow(item);
            } else {
              setSelectedRow(null);
            }
          }}
          segementSummaryRenderer={(acc) => (
            <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
              <Box display="flex" flexGrow={1}>
                <Typography
                  component="span"
                  flexGrow={1}
                  display="flex"
                  padding="8px 32px 4px 20px"
                  fontWeight={500}
                >
                  {acc.segment || "N/A"}
                </Typography>
              </Box>
            </Box>
          )}
        >
          <EmptyView>
            <Box textAlign="center" padding="50px">
              No items to show.
            </Box>
          </EmptyView>
          <ListActions>
            {({ getProcessedData }) => (
              <Menu>
                <MenuItem
                  onClick={async () => {
                    try {
                      await downloadExcel(
                        "CustomerVendorReport",
                        formatHeaderForExcel(docColumns()),
                        formatDataForExcel(getProcessedData(), docRowProcessor)
                      );
                    } catch (e) {
                      dispatch(
                        NotificationActions.notificationAdd({
                          id: new Date().getUTCMilliseconds(),
                          variant: "error",
                          message: "Failed to download Excel.",
                          autoTimeout: true
                        })
                      );
                    }
                  }}
                >
                  {tl("reports.excel")}
                </MenuItem>
              </Menu>
            )}
          </ListActions>
        </List>
        {selectedRow && (
          <ReportDetailView handleViewClose={() => setSelectedRow(null)} data={selectedRow} />
        )}

        {voucherDetail && (
          <VoucherItemView
            data={{ ...voucherDetail, document: voucherDetail.ledgerTransactions }}
            onClose={() => setVoucherDetail(null)}
          />
        )}
      </Box>
    </Box>
  );
}
