import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import produce from "immer";
import { tl } from "../../../components/translate";
import { spFromIdSelector, spFullNameSelector } from "../../../reducers/serviceProvider";
import { OtherSettingsProps } from "./index";
import MedicalLabHeader from "./MedicalLabHeader";
import { notificationAdd } from "../../../actions/notification";
import { useAppDispatch } from "../../../store/hooks";
import UploadStamp from "./UploadStamp";

export default function MedicalReportSettings({
  resourceCentre,
  actions,
  serviceProviders
}: OtherSettingsProps): JSX.Element {
  const { medicalSettings } = resourceCentre.settings;
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = React.useState("");

  const [medicalSp, setMedicalSp] = useState({
    authorizedSP1: null,
    authorizedSP2: null,
    authorizedSP3: null
  });

  const fileUploader = React.useRef<HTMLInputElement>();
  const [tempImg, setTempImg] = React.useState<string | null>(null);
  const [isCropperOpen, setIsCropperOpen] = React.useState(false);
  const [customPrintTitle, setCustomPrintTitle] = useState(
    resourceCentre.settings.medicalSettings.customPrintTitle || ""
  );

  const handleImageChange = (e) => {
    if (!e?.target?.files?.length) {
      return;
    }
    const file = e.target.files[0];
    const lastDotIndex = file.name.lastIndexOf(".");
    const extension = file.name.substring(lastDotIndex + 1);

    if (file.size >= 200000 || !["jpg", "jpeg", "png", "gif"].includes(extension.toLowerCase())) {
      // show error dialog if file size exceeds 200KB
      // eslint-disable-next-line no-param-reassign
      e.target.value = "";
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "File type not valid or size is greater than 200KB.",
          autoTimeout: true
        })
      );
      return;
    }

    setTempImg(URL.createObjectURL(file));
    setIsCropperOpen(true);
  };

  useEffect(() => {
    setMedicalSp({
      authorizedSP1: spFromIdSelector(serviceProviders, medicalSettings.authorizedSP1) || null,
      authorizedSP2: spFromIdSelector(serviceProviders, medicalSettings.authorizedSP2) || null,
      authorizedSP3: spFromIdSelector(serviceProviders, medicalSettings.authorizedSP3) || null
    });
  }, [medicalSettings, serviceProviders]);

  return (
    <Box marginTop="10px">
      <Box display="flex" alignItems="center" maxWidth="52vw" mt="8px">
        <Typography style={{ width: "500px" }}>{tl("authorisedSignature1")}</Typography>
        <Autocomplete
          data-testmation="authorizedSP1"
          options={serviceProviders}
          getOptionLabel={(option) => spFullNameSelector(option)}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.id}>
              {spFullNameSelector(option)}
            </li>
          )}
          onChange={async (e, v) => {
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.medicalSettings.authorizedSP1 = v ? v.id : null;
            });
            actions.putResourceCentre(newRc);
          }}
          value={medicalSp.authorizedSP1}
          style={{ width: "100%" }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw">
        <Typography style={{ width: "500px" }}>{tl("authorisedSignature2")}</Typography>
        <Autocomplete
          options={serviceProviders}
          getOptionLabel={(option) => spFullNameSelector(option)}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.id}>
              {spFullNameSelector(option)}
            </li>
          )}
          style={{ width: "100%" }}
          onChange={async (e, v) => {
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.medicalSettings.authorizedSP2 = v ? v.id : null;
            });
            actions.putResourceCentre(newRc);
          }}
          value={medicalSp.authorizedSP2}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box display="flex" justifyContent="center" sx={{ mt: "20px" }} flexDirection="column">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={resourceCentre.settings.medicalSettings.includeLetterhead}
              onChange={() => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.includeLetterhead =
                    !resourceCentre.settings.medicalSettings.includeLetterhead;
                });
                actions.putResourceCentre(newRc);
              }}
            />
          }
          label={tl("clinicSettings.includeLetterheadInMedicalReport")}
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={resourceCentre.settings.medicalSettings.showBarCode}
              onChange={() => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.showBarCode =
                    !resourceCentre.settings.medicalSettings.showBarCode;
                });
                actions.putResourceCentre(newRc);
              }}
            />
          }
          label={tl("clinicSettings.showBarCodeInMedicalReport")}
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={resourceCentre.settings.medicalSettings.showQrCode}
              onChange={() => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.showQrCode =
                    !resourceCentre.settings.medicalSettings.showQrCode;
                });
                actions.putResourceCentre(newRc);
              }}
            />
          }
          label={tl("clinicSettings.showQRCodeInMedicalReport")}
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={resourceCentre.settings.medicalSettings.showReferrer}
              onChange={() => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.showReferrer =
                    !resourceCentre.settings.medicalSettings.showReferrer;
                });
                actions.putResourceCentre(newRc);
              }}
            />
          }
          label={tl("clinicSettings.showReferrerInMedicalReport")}
        />
      </Box>
      <UploadStamp />
      <Box marginTop="20px" marginBottom="20px">
        <Typography sx={{ fontWeight: 600 }} gutterBottom>
          Custom Print Title
        </Typography>

        <TextField
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          placeholder="Provide custom print title"
          value={customPrintTitle}
          style={{ width: "250px" }}
          onChange={(e) => {
            if (e.target.value.length > 50) {
              setErrorMessage("**Title can not exceed more than 50 characters");
              return;
            }
            setErrorMessage("");
            setCustomPrintTitle(e.target.value);
          }}
          onFocus={() => setErrorMessage("")}
          onBlur={() => {
            if (customPrintTitle.length <= 50) {
              const newRc = produce(resourceCentre, (draft) => {
                draft.settings.medicalSettings.customPrintTitle = customPrintTitle;
              });
              actions.putResourceCentre(newRc);
            }
          }}
        />

        {errorMessage && (
          <Typography color="red" my={2}>
            {errorMessage}
          </Typography>
        )}
      </Box>

      <MedicalLabHeader
        fileUploaderRef={fileUploader}
        onImageChange={handleImageChange}
        isCropperOpen={isCropperOpen}
        setIsCropperOpen={setIsCropperOpen}
        tempImg={tempImg}
      />
    </Box>
  );
}
