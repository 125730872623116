import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { getStockExpiryReport } from "../../api/reports";
import Filters, { STOCK_STATUSES } from "./Filters";
import { notificationAdd } from "../../actions/notification";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadExcel } from "../../helpers/files";
import { getValue } from "../../helpers/formatters";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import { ProductType } from "../Stock/StockCreateEdit/CreateStockProduct";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import { rupeeDisplay } from "../../helpers/rupee";
import { errorFetchMessage, MODULE } from "../../helpers/messages";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

export interface StockExpiryData {
  id: number;
  expiryDate: string;
  productCode: number;
  name: string;
  batchId: string;
  unit: string;
  package: string;
  quantity: number;
  minStockQuantity: number;
  perUnitSellingPrice: number;
  perUnitPurchasePrice: number;
}

const docColumns = [
  "Expiry Date",
  "ProductCode",
  "name",
  "Batch No.",
  "Unit",
  "Package",
  "quantity",
  "Minimum Stock Quantity",
  "Per Unit Selling Price Excl. Vat",
  "Sales Value",
  "Per Unit Purchase Price Excl. Vat",
  "Purchase Value"
];

const docRowProcessor = ({
  name,
  batchId,
  unit,
  package: p,
  expiryDate,
  perUnitPurchasePrice,
  perUnitSellingPrice,
  quantity,
  minStockQuantity,
  productCode,
  // eslint-disable-next-line camelcase
  __meta__row_type
}: // eslint-disable-next-line camelcase
Partial<StockExpiryData & { __meta__row_type: string }>) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    expiryDate ? convertADtoBS(moment(expiryDate)).formatted4 : "",
    productCode || "",
    name,
    batchId,
    unit,
    p,
    quantity,
    minStockQuantity,
    perUnitSellingPrice,
    (perUnitSellingPrice || 0) * (quantity || 0),
    perUnitPurchasePrice,
    (perUnitPurchasePrice || 0) * (quantity || 0)
  ].map((item) => getValue(`${item}`));
};

const StockExpiryReport = (): JSX.Element => {
  const [filters, setFilters] = React.useState({
    productType: ProductType.Sellable,
    productItems: [],
    stockStatus: [STOCK_STATUSES.EXPIRING_IN_90_DAYS]
  });

  const dispatch = useDispatch();
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [stockExpiryReportData, setStockExpiryReportData] = React.useState<StockExpiryData[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        setIsRequestLoading(true);
        const response = await getStockExpiryReport(filters);
        setStockExpiryReportData(response);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            variant: "error",
            message: error?.data?.message || errorFetchMessage(MODULE.STOCK_EXPIRY_REPORT),
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [filters, dispatch]);
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Box overflow="auto hidden">
      <Box minWidth={isRnWebView ? "1000px" : "auto"}>
        <Box m={4}>
          <Filters
            filters={filters}
            onSetFilters={(newFilters) => setFilters(newFilters)}
            showPeriod={false}
            isRequestLoading={isRequestLoading}
          />
        </Box>
        <Box className="stockExpiryReport">
          <List
            automation="stockExpiryReportList"
            data={stockExpiryReportData}
            rowHeight={50}
            adjustHeightToContents
            defaultSortColumn="name"
            columns={[
              {
                key: "expiryDate",
                label: "Expiry Date",
                sortable: true,
                formatter: ({ expiryDate }) => {
                  const expired = moment(expiryDate).isBefore(moment());
                  return (
                    <Typography
                      sx={{
                        color: expired ? "red" : "inherit"
                      }}
                    >
                      {expiryDate ? convertADtoBS(moment(expiryDate)).formatted4 : ""}
                    </Typography>
                  );
                }
              },
              {
                key: "productCode",
                label: "Product Code",
                sortable: true,
                formatter: ({ productCode }) => <Typography>{productCode}</Typography>
              },
              {
                key: "name",
                label: tl("reports.stock.productName"),
                segmentable: true,
                segmentBy: ({ name }) => name,
                sortable: true,
                formatter: ({ name }) => <Typography>{name}</Typography>
              },
              {
                key: "batchId",
                label: tl("reports.batchId"),
                sortable: true,
                formatter: ({ batchId }) => <Typography>{batchId}</Typography>
              },
              {
                key: "unit/package",
                label: "Unit/Package",
                formatter: ({ unit = "", package: p = "" }) => (
                  <Typography>{`${unit}/${p}`}</Typography>
                )
              },
              {
                key: "quantity",
                label: "Quantity",
                sortable: true,
                formatter: ({ quantity, minStockQuantity }) => (
                  <Typography
                    sx={{
                      color: (quantity || 0) < (minStockQuantity || 0) ? "red" : "inherit"
                    }}
                  >
                    {quantity}
                  </Typography>
                )
              },
              {
                key: "minStockQuantity",
                label: "Min Stock Qty",
                sortable: true,
                formatter: ({ minStockQuantity }) => <Typography>{minStockQuantity}</Typography>
              },
              {
                key: "perUnitSellingPrice",
                label: "Selling Price",
                sortable: true,
                formatter: ({ perUnitSellingPrice }) => (
                  <Typography>{perUnitSellingPrice}</Typography>
                )
              },
              {
                key: "salesValue",
                label: "Sales Value",
                formatter: ({ perUnitSellingPrice, quantity }) => (
                  <Typography>{(perUnitSellingPrice || 0) * (quantity || 0)}</Typography>
                )
              },
              {
                key: "perUnitPurchasePrice",
                label: "Purchase Price",
                sortable: true,
                formatter: ({ perUnitPurchasePrice }) => (
                  <Typography>{perUnitPurchasePrice}</Typography>
                )
              },
              {
                key: "purchaseValue",
                label: "Purchase Value",
                formatter: ({ perUnitPurchasePrice, quantity }) => (
                  <Typography>{(perUnitPurchasePrice || 0) * (quantity || 0)}</Typography>
                )
              }
            ]}
            segementSummaryRenderer={(acc) => (
              <Box
                sx={{ background: "#e6e6e6" }}
                pr="30px"
                display="flex"
                flexGrow={1}
                alignItems="center"
              >
                <Typography
                  component="span"
                  flexGrow={1}
                  display="flex"
                  padding="8px 32px 4px 20px"
                  fontWeight={500}
                >
                  {acc.segment}
                </Typography>
                <Typography flexBasis="200px" pt="4px" textAlign="right">
                  {rupeeDisplay(
                    Math.floor(acc.rows.reduce((prev, cur) => prev + cur.stockValue, 0) || 0)
                  )}
                </Typography>
              </Box>
            )}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
            <ListActions>
              {({ getProcessedData }) => (
                <Menu>
                  <MenuItem
                    onClick={async () => {
                      try {
                        await downloadExcel(
                          "Stock Summary Report",
                          formatHeaderForExcel(docColumns),
                          formatDataForExcel(getProcessedData(), docRowProcessor)
                        );
                      } catch (err) {
                        dispatch(
                          notificationAdd({
                            id: new Date().getUTCMilliseconds(),
                            variant: "error",
                            message: "Failed to download Excel.",
                            autoTimeout: true
                          })
                        );
                      }
                    }}
                  >
                    {tl("reports.excel")}
                  </MenuItem>
                </Menu>
              )}
            </ListActions>
          </List>
          <Box className="totalBar">
            <Box display="flex" paddingLeft="20px">
              {t("reports.total")}
            </Box>
            <Box display="flex" flexBasis="192px" paddingRight="26px" justifyContent="flex-end">
              <Typography>
                {rupeeDisplay(
                  Math.floor(
                    stockExpiryReportData.reduce(
                      (prev, cur) => prev + cur.perUnitPurchasePrice * cur.quantity,
                      0
                    ) || 0
                  )
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default StockExpiryReport;
