import React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import { getReminderReport } from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { ReminderInterface } from "../../interfaces/ReminderInterface";
import Filters, { Entity } from "./Filters";
import List, { EmptyView } from "../../components/OList/List";
import { RootState } from "../../store";
import { serviceProvidersSortedSelector } from "../../reducers/serviceProvider";
import { notificationAdd } from "../../actions/notification";
import { commonErrorMessage } from "../../helpers/messages";
import { t } from "../../components/translate";
import ReminderDetailView from "./ReminderDetailView";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const addSpName = (spsDict, data) =>
  data.map((item) => ({
    ...item,
    clientId: item.extraData.clientId,
    ...(item.extraData.serviceProviderId && { spName: spsDict[item.extraData.serviceProviderId] })
  }));

export default function ReminderReport(): JSX.Element {
  const dispatch = useDispatch();
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [data, setData] = React.useState<ReminderInterface[]>([]);
  const [selectedRow, setSelectedRow] = React.useState<ReminderInterface | null>(null);
  const resourceSps = useSelector((state: RootState) => serviceProvidersSortedSelector(state));
  const spsDict = React.useMemo(() => {
    const draft = {};
    resourceSps.forEach((sp) => {
      draft[sp.id] = `${sp.title || ""} ${sp.firstName} ${sp.lastName}`;
    });
    return draft;
  }, [resourceSps]);

  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: calFns.endOfDay(new Date()).toDate(),
    entity: Entity.BOOKING,
    serviceProviderIds: []
  });
  React.useEffect(() => {
    const getData = async () => {
      try {
        setIsRequestLoading(true);
        const response = await getReminderReport(filters);
        setData(addSpName(spsDict, response));
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            variant: "error",
            message: error?.data?.message || commonErrorMessage,
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    };
    getData();
  }, [filters]);
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Box overflow="auto hidden">
      <Box minWidth={isRnWebView ? "1000px" : "auto"}>
        <Box m="32px">
          <Filters
            isReminderReport
            filters={filters}
            onSetFilters={(filter) => setFilters(filter)}
            isRequestLoading={isRequestLoading}
          />
        </Box>
        <Box marginTop="32px" height="calc(100vh - 288px)">
          <List
            automation="stockAuditList"
            data={data}
            rowHeight={50}
            activeRow={0}
            adjustHeightToContents
            defaultSortColumn="serviceProvider"
            defaultSortOrder={-1}
            columns={[
              {
                key: "serviceProvider",
                label: "Service Provider",
                formatter: ({ spName }) => <Typography>{spName}</Typography>,
                segmentable: true,
                segmentBy: ({ spName }) => spName,
                sortable: true
              },
              {
                key: "on",
                label: "Reminder On",
                sortable: true,
                formatter: ({ on }) => (
                  <Typography>{calFns.convertADtoBS(new Date(on)).formatted4}</Typography>
                )
              },
              {
                sortable: true,
                key: "clientId",
                label: "Client Id",
                formatter: ({ clientId }) => <Typography>{clientId}</Typography>
              },
              {
                sortable: true,
                key: "type",
                label: "Type",
                formatter: ({ entityName }) => <Typography>{startCase(entityName)}</Typography>
              },
              {
                sortable: true,
                key: "remark",
                label: "Remarks",
                formatter: ({ remark }) => <Typography>{remark}</Typography>
              }
            ]}
            onRowClick={(row) => setSelectedRow(row)}
            segementSummaryRenderer={(acc) => (
              <Box
                flexGrow={1}
                pt={2}
                pl={2}
                justifyContent="space-between"
                style={{ background: "#e6e6e6" }}
                display="flex"
                paddingRight="30px"
              >
                <Typography component="span" fontWeight={500}>
                  {acc.segment || "N/A"}
                </Typography>
                <Typography>{acc.rows.length}</Typography>
              </Box>
            )}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
          </List>
          <Box className="totalBar">
            <Box display="flex" paddingLeft="20px">
              {t("reports.total")}
            </Box>
            <Box display="flex" flexBasis="192px" paddingRight="26px" justifyContent="flex-end">
              <Typography>{data.length}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {selectedRow && (
        <ReminderDetailView
          selectedEntity={filters.entity}
          data={selectedRow}
          onClose={() => setSelectedRow(null)}
        />
      )}
    </Box>
  );
}
