import React from "react";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { chain } from "mathjs";
import startCase from "lodash/startCase";
import "./PrintBill.scss";
import moment from "moment";
import { DocumentTypes } from "../../../interfaces/BillInterfaces";
import { rupeeDisplay } from "../../../helpers/rupee";
import { scaleFont } from "../../../components/Print/Print";
import { tl, t } from "../../../components/translate";
import classNames from "../../../helpers/classNames";
import { spFullNameSelector, spFromIdSelector } from "../../../reducers/serviceProvider";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import hasOwnProperty from "../../../helpers/object";

interface Props {
  billData: Record<string, any>;
  SPs: Array<ServiceProvider>;
}

const DescribeBillV1 = ({ billData, SPs }: Props): JSX.Element => {
  if (!billData || !billData.document) return null;
  const billColumns = ["sNo", "description", "quantity", "perUnit", "grossTotal"];
  const subtotal = billData.document.billItems.reduce((sum, item) => sum + item.grossTotal, 0);
  return (
    <Box>
      <Box
        component="div"
        display="flex"
        marginBottom="0.5rem"
        className=" borderBotBlack1"
        paddingBottom="0.2cm"
      >
        {billColumns.map((column, i) => (
          <Box
            component="div"
            key={column}
            className={classNames({ grow1: i === 0 || i === 2, flex3: i === 1, flex1: i > 2 })}
          >
            <Typography component="div">
              <Box
                textAlign={i > 2 ? "center" : "left"}
                style={{ textTransform: "uppercase" }}
                fontSize={scaleFont("0.27cm")}
              >
                {tl(`billing.billItem.${column}`)}
              </Box>
            </Typography>
          </Box>
        ))}
      </Box>

      <Box className="borderBotBlack1" component="div" paddingBottom="0.45cm">
        {billData.document.billItems.map((item) => (
          <Box component="div" key={item.sNo} display="flex">
            {billColumns.map((column, i) => (
              <Box
                component="div"
                key={column}
                className={classNames({ grow1: i === 0 || i === 2, flex3: i === 1, flex1: i > 2 })}
                paddingTop="0.2cm"
              >
                <Typography component="div">
                  <Box
                    className="blockContent"
                    sx={{ display: column === "description" ? "inline" : "block" }}
                    fontWeight={["description", "grossTotal"].includes(column) ? 500 : 400}
                    fontSize={scaleFont("0.33cm")}
                    textAlign={i > 2 ? "center" : "left"}
                  >
                    {item[column]} {i === 2 && item.unit}{" "}
                    {column === "description" &&
                      item.serviceProviderId &&
                      `(${spFullNameSelector(spFromIdSelector(SPs, item.serviceProviderId))})`}
                  </Box>
                  {column === "description" && (
                    <Box
                      sx={{ whiteSpace: "pre-wrap", display: "inline" }}
                      fontSize={scaleFont("0.23cm")}
                    >
                      {item.batchInfo.batchId && `Batch ${item.batchInfo.batchId}`}
                      {item.batchInfo.expiryDate &&
                        `, Expiring ${moment(item.batchInfo.expiryDate).format("YYYY/MM/DD")}`}
                    </Box>
                  )}
                  {column === "description" &&
                    item.subItems &&
                    item.subItems.map((subitem) => (
                      <Box key={subitem.id} paddingTop="0.05cm" className="blockContent">
                        {`◦ ${subitem.description}`}
                        {hasOwnProperty(subitem, "serviceProviderId") &&
                          subitem.serviceProviderId && (
                            <span style={{ marginLeft: "8px" }}>
                              {`(${spFullNameSelector(
                                spFromIdSelector(SPs, subitem.serviceProviderId)
                              )})`}
                            </span>
                          )}
                      </Box>
                    ))}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box component="div" display="flex" paddingTop="1cm" className="blockContent">
        <Box component="div" width="70%">
          {Boolean(billData.document.remarks) && (
            <Box marginBottom="0.3cm">
              <Typography variant="subtitle2" align="left" component="div" display="block">
                <Box fontSize={scaleFont("0.3cm")} style={{ textTransform: "uppercase" }}>
                  {tl("billing.remarks")}
                </Box>
              </Typography>
              <Typography align="left" component="div">
                <Box
                  fontWeight={400}
                  fontSize={scaleFont("0.33cm")}
                  marginRight="2rem"
                  marginTop="0.1cm"
                >
                  {billData.document.remarks}
                </Box>
              </Typography>
            </Box>
          )}
          {Boolean(billData?.paymentInfo?.paymentMethod) && (
            <>
              <Typography variant="subtitle2" align="left" component="div" display="block">
                <Box fontSize={scaleFont("0.3cm")} style={{ textTransform: "uppercase" }}>
                  {tl("billing.paymentMethod")}
                </Box>
              </Typography>
              <Typography align="left" component="div">
                <Box
                  fontWeight={400}
                  fontSize={scaleFont("0.33cm")}
                  marginRight="2rem"
                  marginTop="0.1cm"
                >
                  {startCase(billData.paymentInfo?.paymentMethod)}
                </Box>
              </Typography>
            </>
          )}
        </Box>
        <Box component="div" width="30%">
          {billData.document.summary.totalAmount !== subtotal && (
            <Box paddingBottom="0.6cm">
              <Typography component="div">
                <Box
                  textAlign="right"
                  fontSize={scaleFont("0.3cm")}
                  fontWeight={600}
                  style={{ textTransform: "uppercase" }}
                >
                  {tl("billing.summary")}
                </Box>
              </Typography>
              <Box component="div" marginTop="0.1cm">
                <Grid container justifyContent="space-between">
                  <Typography align="left" component="div">
                    <Box
                      fontSize={scaleFont("0.3cm")}
                      fontWeight={500}
                      style={{ textTransform: "uppercase" }}
                    >
                      {tl("billing.subtotal")}
                    </Box>
                  </Typography>
                  <Typography align="right" component="div">
                    {rupeeDisplay(subtotal)}
                  </Typography>
                </Grid>
              </Box>
              {billData.document.summary.discount > 0 && (
                <Box component="div" marginTop="0.1cm">
                  <Grid container justifyContent="space-between">
                    <Typography align="left" component="div">
                      <Box
                        fontSize={scaleFont("0.3cm")}
                        fontWeight={500}
                        style={{ textTransform: "uppercase" }}
                      >
                        {tl("billing.discount")}
                      </Box>
                    </Typography>
                    <Typography align="right" component="div">
                      {`${rupeeDisplay(billData.document.summary.discount)} (${
                        billData.document.summary.discountPct
                      }%)`}
                    </Typography>
                  </Grid>
                </Box>
              )}
              {Boolean(billData.document.summary.taxAmount) && (
                <Box component="div" marginTop="0.5rem" marginBottom="1rem">
                  <Grid container justifyContent="space-between">
                    <Typography align="left" component="div">
                      <Box
                        fontSize={scaleFont("0.3cm")}
                        fontWeight={500}
                        style={{ textTransform: "uppercase" }}
                      >
                        {tl("billing.taxAmount")}
                      </Box>
                    </Typography>
                    <Typography align="right" component="div">
                      {`${rupeeDisplay(billData.document.summary.taxAmount)} (13%)`}
                    </Typography>
                  </Grid>
                </Box>
              )}
            </Box>
          )}
          <Box component="div">
            <Typography align="right" component="div">
              <Box
                fontSize={scaleFont("0.3cm")}
                fontWeight={600}
                style={{ textTransform: "uppercase" }}
              >
                {tl("billing.total")}
              </Box>
            </Typography>
            <Typography align="right" component="div">
              <Box fontWeight={500} fontSize={scaleFont("0.6cm")}>
                {rupeeDisplay(billData.document.summary.totalAmount)}
              </Box>
            </Typography>
          </Box>
          {billData.type !== DocumentTypes.CREDITNOTE && (
            <Typography align="right" component="div">
              <Box fontWeight={400} fontSize={scaleFont("0.3cm")}>
                {billData.document.summary.inWords} only
              </Box>
            </Typography>
          )}
          {!billData.paymentInfo?.paid && (
            <Box component="div" marginTop="0.2cm">
              <Typography align="right" component="div">
                <Box
                  fontSize={scaleFont("0.3cm")}
                  fontWeight={600}
                  style={{ textTransform: "uppercase" }}
                >
                  {t("reports.dueAmount")}
                </Box>
              </Typography>
              <Typography align="right" component="div">
                <Box fontWeight={500} fontSize={scaleFont("0.35cm")}>
                  {rupeeDisplay(
                    chain(billData.summary?.totalAmount)
                      .subtract(billData.paymentInfo?.paidAmount || 0)
                      .done()
                  )}
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DescribeBillV1;
