import React from "react";
import { Box, Typography } from "@mui/material";
import Barcode from "react-barcode";
import { capitalize } from "lodash";
import { ResourceCentre } from "../../LabprintComponents/LabPrintLetterhead";
import LabPrintFooter from "../../LabprintComponents/LabPrintFooter";
import LabPrintLabInfo from "../../LabprintComponents/LabPrintLabInfo";
import AuthorizedSPInfo from "../FixedFooter/AuthorizedSPInfo";
import LabPrintCoreData from "./BlockCategoryCoreData";
import {
  getSectionStyle,
  getSettingBasedDate,
  groupLabTestByCategory,
  showDynamicSignature
} from "../../LabPrintFunctions";

import { LabObject, LabPrintSettings } from "../../../../../interfaces/LabInterfaces";
import { AuthorizedSp } from "../../LabprintComponents/AuthorizedSPInfo";
import { clientFullNameSelector } from "../../../../../reducers/client";
import { scaleFont } from "../../../../../components/Print/Print";
import { CustomEl } from "../../../LabEntry/LabTestSubRow";
import { showDOBAndGender } from "../../../../Billing/PrintBill/EightyMmPrint/ClientInfo";
import { getCustomerNumber } from "../../../../Client/ClientList";
import { getLabHeader } from "../A5/A5Template";
import CustomHeader from "../../CustomHeader";

interface ExtraProps {
  unfixFooter?: boolean;
}

interface BlockCategoryTemplateProps {
  resourceCentre: ResourceCentre;
  labData: LabObject;
  settings: LabPrintSettings;
  showAdditionalLabData: boolean;
  otherProps?: ExtraProps;
  showDobOnLabPrint?: boolean;
}

function LabelledField({ label, value }: { label: string; value: string | number }) {
  return (
    <Box display="flex" alignItems="flex-start">
      <Typography style={{ width: "2.9cm", fontSize: scaleFont("0.28cm", 1.2) }}>
        {label}
      </Typography>
      <Typography
        style={{
          flex: 1,
          fontSize: scaleFont("0.28cm", 1.2)
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}

const BlockCategoryTemplate: React.FC<BlockCategoryTemplateProps> = ({
  settings,
  resourceCentre,
  labData,
  otherProps,
  showAdditionalLabData,
  showDobOnLabPrint
}) => {
  const authorizedLabSp1: AuthorizedSp = labData.results?.approvedBy?.authorizedSP1 || null;
  const authorizedLabSp2: AuthorizedSp = labData.results?.approvedBy?.authorizedSP2 || null;
  const authorizedLabSp3: AuthorizedSp = labData.results?.approvedBy?.authorizedSP3 || null;
  const authorizedLabSp4: AuthorizedSp = labData.results?.approvedBy?.authorizedSP4 || null;

  const {
    showLetterhead,
    showFooter,
    headerColor,
    dateSetting,
    showPrintedOnTime,
    showReportOnTime,
    showSampleTakenTime,
    showSampleTakenDate,
    showReportOnDate,
    showPrintedOnDate,
    centralizedHeader,
    clinicNameFontScale,
    showLabRemarks,
    labRemarks,
    showMethods,
    customTitle,
    showRanges,
    showUnit,
    showReading,
    hideFooterLogoCompletely,
    showRangeFlag,
    useCustomLabTestNumber,
    showSampleCollectedBy,
    customHeaderImage
  } = settings;

  const { client } = labData;

  const letterHeadClientInfo = (
    <Box display="flex" p="0.03cm 0.3cm" justifyContent="space-between">
      <Box width="50%">
        <Typography
          style={{
            fontSize: scaleFont("0.3cm", 1.6),
            textTransform: "uppercase"
          }}
        >
          {clientFullNameSelector(client)}
          <span style={{ marginLeft: "5px" }}>
            {showDOBAndGender(client.gender, client.dob, true)}
          </span>
        </Typography>
        {labData.customer?.customerNumber && (
          <LabelledField
            label="Patient ID"
            value={getCustomerNumber(labData.customer?.customerNumber)}
          />
        )}
        {showDobOnLabPrint && client && client.dob && (
          <LabelledField label="Date of Birth" value={getSettingBasedDate("BS", client.dob)} />
        )}
        {client.phone && <LabelledField label="Phone" value={client.phone} />}
        {client.address && <LabelledField label="Address" value={capitalize(client.address)} />}
        {labData.ipd?.ipdNumber && <LabelledField label="Ipd No." value={labData.ipd.ipdNumber} />}
        {labData.results?.bed && (
          <LabelledField label="Bed No." value={labData.results?.bed?.name} />
        )}
        <LabelledField
          label="Test ID"
          value={useCustomLabTestNumber ? labData.labTestNumber || labData.id : labData.id}
        />
        {labData.client.passportInfo?.passportNumber && (
          <LabelledField label="Passport no" value={labData.client.passportInfo.passportNumber} />
        )}
        {labData.referrers && (
          <LabelledField label="Referred By" value={labData.referrers.toUpperCase()} />
        )}
        {labData.extraReferrer?.name && (
          <LabelledField label="Extra Referrer" value={labData.extraReferrer.name.toUpperCase()} />
        )}

        <Barcode
          value={labData.id}
          width={2}
          displayValue={false}
          fontSize={5}
          height={20}
          margin={2}
          font="arial"
          renderer="img"
        />
      </Box>
      <Box ml="0.2cm">
        <LabPrintLabInfo
          dateSetting={dateSetting}
          showPrintedOnTime={showPrintedOnTime}
          showReportOnTime={showReportOnTime}
          showSampleTakenTime={showSampleTakenTime}
          showSampleTakenDate={showSampleTakenDate}
          showPrintedOnDate={showPrintedOnDate}
          showReportOnDate={showReportOnDate}
          sampleRemarks={
            Array.isArray(labData.remarks) && labData.remarks.length > 0
              ? labData.remarks[0].remark
              : null
          }
          collectionDate={labData.collectionDate}
          resultDate={labData.resultDate}
          labIdentifier={labData.labIdentifier || ""}
          modifiedDate={labData.results.modifiedDate || null}
          mfField="mf1"
        />
      </Box>
    </Box>
  );

  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <td>
            {!showLetterhead && (
              <>
                <Box height="3cm" width="100%" />
                <Box pl="10mm" pr="10mm">
                  {letterHeadClientInfo}
                </Box>
              </>
            )}
            {showLetterhead && (
              <Box
                width="100%"
                style={{
                  ...getSectionStyle(headerColor).headerStyle,
                  paddingTop: "4mm",
                  paddingBottom: "3mm",
                  marginBottom: "0mm"
                }}
              >
                {customHeaderImage ? (
                  <CustomHeader imageUrl={customHeaderImage} />
                ) : (
                  getLabHeader(centralizedHeader, resourceCentre, clinicNameFontScale)
                )}
                {letterHeadClientInfo}
              </Box>
            )}

            <hr style={{ border: "1px solid black" }} />
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
              {customTitle !== "" && (
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "0.5cm",
                    marginBottom: "0.1cm",
                    fontWeight: 600,
                    textTransform: "uppercase"
                  }}
                >
                  {customTitle === null || customTitle === undefined
                    ? "Lab Test Result"
                    : customTitle}
                </Typography>
              )}

              <Box mt="0.3cm" width="calc(100vw - 25mm)">
                <LabPrintCoreData
                  showAdditionalLabData={showAdditionalLabData}
                  labData={groupLabTestByCategory(labData)}
                  showMethods={showMethods}
                  categorySortOrder={settings.categoryOrder}
                  showRanges={showRanges}
                  showUnit={showUnit}
                  showReading={showReading}
                  showRangeFlag={showRangeFlag}
                  showSampleCollectedBy={showSampleCollectedBy}
                />
              </Box>
              {showLabRemarks && (
                <Typography style={{ marginTop: "0.15cm" }}>
                  <CustomEl data={labRemarks} />
                </Typography>
              )}
            </Box>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <Box height="7.5cm" marginTop="-0.5cm" /> {/* position fixed offset */}
            <Box position="fixed" bottom="0px" height="7.5cm" width="100vw">
              {labData.results?.currentApprovedUser ? (
                <Box
                  style={{ pageBreakInside: "avoid" }}
                  display="flex"
                  width="100%"
                  right="0px"
                  mt="0.22cm"
                  justifyContent="space-between"
                  px="1cm"
                >
                  <AuthorizedSPInfo sp={labData.results?.currentApprovedUser} />
                </Box>
              ) : (
                <Box
                  style={{ pageBreakInside: "avoid" }}
                  display="flex"
                  width="100%"
                  right="0px"
                  mt="0.22cm"
                  justifyContent="space-between"
                  px="1cm"
                >
                  {showDynamicSignature(authorizedLabSp1) && (
                    <Box>
                      <AuthorizedSPInfo sp={authorizedLabSp1} width="4cm" />
                    </Box>
                  )}
                  {showDynamicSignature(authorizedLabSp2, authorizedLabSp1) && (
                    <Box>
                      <AuthorizedSPInfo sp={authorizedLabSp2} width="4cm" />
                    </Box>
                  )}
                  {showDynamicSignature(authorizedLabSp3, authorizedLabSp1, authorizedLabSp2) && (
                    <Box>
                      <AuthorizedSPInfo sp={authorizedLabSp3} width="4cm" />
                    </Box>
                  )}
                  {showDynamicSignature(
                    authorizedLabSp4,
                    authorizedLabSp1,
                    authorizedLabSp2,
                    authorizedLabSp3
                  ) && (
                    <Box>
                      <AuthorizedSPInfo sp={authorizedLabSp4} width="4cm" />
                    </Box>
                  )}
                </Box>
              )}
              <LabPrintFooter
                uuid={labData.uuid}
                headerColor={headerColor}
                id={labData.id}
                unfixFooter={otherProps?.unfixFooter}
                showFooter={showFooter}
                hideFooterLogoCompletely={hideFooterLogoCompletely}
              />
            </Box>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

BlockCategoryTemplate.defaultProps = {
  otherProps: {}
};

export default BlockCategoryTemplate;
