import { Box, Button, Typography } from "@mui/material";
import React from "react";
import produce from "immer";
import { useParams } from "react-router";
import CustomHeader from "../../Lab/LabPrint/CustomHeader";
import ImageCrop from "../../../components/ImageCrop/ImageCrop";
import { customHeaderImagePresignedS3 } from "../../../api/resourceCentre";
import { notificationAdd } from "../../../actions/notification";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resourceCentreActions as actions } from "../../../actions";

const MedicalLabHeader = ({
  fileUploaderRef,
  onImageChange,
  isCropperOpen,
  setIsCropperOpen,
  tempImg
}: {
  fileUploaderRef: HTMLInputElement;
  onImageChange: (e) => void;
  isCropperOpen: boolean;
  setIsCropperOpen: (data) => void;
  tempImg: Blob | null;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { resourceCentres } = useAppSelector((state: RootState) => state.resources);
  const rcId = Number(params.id);
  const resourceCentre: ResourceCentre = resourceCentres.find((item) => item.id === rcId);

  const { medicalSettings } = resourceCentre.settings;

  return (
    <Box mt={2}>
      <Typography sx={{ fontWeight: 600 }} gutterBottom>
        Custom Print Header
      </Typography>
      <Box>
        {medicalSettings.customHeaderImage && (
          <CustomHeader imageUrl={medicalSettings.customHeaderImage} />
        )}
      </Box>
      <label htmlFor="fileUploader">
        <input
          className="imageUpload"
          data-testmation="uploadBannerInput"
          type="file"
          accept=".jpg,.jpeg,.gif,.png"
          ref={fileUploaderRef}
          name="fileUploader"
          onChange={onImageChange}
          id="fileUploader"
          style={{
            display: "none"
          }}
        />
        <Button variant="contained" component="span">
          Upload Custom Header
        </Button>
      </label>
      {medicalSettings.customHeaderImage && (
        <Button
          variant="contained"
          sx={{ backgroundColor: "red", marginLeft: 1 }}
          onClick={() => {
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.medicalSettings.customHeaderImage = "";
            });
            dispatch(actions.putResourceCentre(newRc));
          }}
        >
          Remove Header Image
        </Button>
      )}
      <ImageCrop
        image={tempImg}
        dynamicRatio={false}
        defaultRatio={{ x: 8, y: 1 }}
        isOpen={isCropperOpen}
        onClose={() => setIsCropperOpen(false)}
        onSave={async (img) => {
          try {
            const data = await customHeaderImagePresignedS3(img);
            if (!data) {
              return;
            }
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.medicalSettings.customHeaderImage = data;
            });
            dispatch(actions.putResourceCentre(newRc));
          } catch (err) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: "Failed to upload custom header",
                autoTimeout: true
              })
            );
          }
        }}
      />
    </Box>
  );
};

export default MedicalLabHeader;
