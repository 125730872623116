import React from "react";
import { Typography, Box } from "@mui/material";
import { chain, round } from "mathjs";
import startCase from "lodash/startCase";
import moment from "moment";
import "./PrintBill.scss";
import { useSelector } from "react-redux";
import { BillType, DiscountBasedOn, DocumentTypes } from "../../../interfaces/BillInterfaces";
import { rupeeDisplay } from "../../../helpers/rupee";
import { scaleFont } from "../../../components/Print/Print";
import { tl, t } from "../../../components/translate";
import classNames from "../../../helpers/classNames";
import { spFullNameSelector, spFromIdSelector } from "../../../reducers/serviceProvider";
import { getSectionStyle } from "../../Lab/LabPrint/LabPrintFunctions";
import hasOwnProperty from "../../../helpers/object";
import { RootState } from "../../../store";
import { ForceTemplate } from "./BillPrintHelpers";
import capitalizedName from "../../../helpers/nameCapitalizer";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import {
  BillColumns,
  getBillColumns,
  showUnitAfterQuantity,
  showInfoColumn,
  marginForNameColumn,
  getColumnTextStyles
} from "../../../actions/helpers/billHelper";

const SummaryFieldBankPaper = ({
  label,
  value,
  labelStyle,
  valueStyle,
  alignLeft
}: {
  label: string | React.ReactNode;
  value: number | string;
  labelStyle?: { [key: string]: string };
  valueStyle?: { [key: string]: string };
  alignLeft?: boolean;
}) => {
  const data = typeof value === "number" ? rupeeDisplay(round(Number(value || 0), 2)) : value;
  return (
    <Box display="flex" justifyContent={`${alignLeft ? "flex-start" : "flex-end"}`} width="100%">
      <Typography fontSize="0.23cm" fontWeight={500} sx={{ ...labelStyle }}>
        {label}
      </Typography>
      <Typography fontSize="0.28cm" width="2.5cm" fontWeight={500} sx={{ ...valueStyle }}>
        {data}
      </Typography>
    </Box>
  );
};

SummaryFieldBankPaper.defaultProps = {
  labelStyle: {},
  valueStyle: {},
  alignLeft: false
};

const BillDescriptionBankPaper = ({
  billData,
  SPs,
  relatedSettings,
  clientBalance,
  forceTemplate,
  hidePaymentMethodOnPrint
}: {
  billData: BillType;
  SPs: ServiceProvider[];
  relatedSettings: { [key: string]: string };
  clientBalance: number;
  forceTemplate: string;
  hidePaymentMethodOnPrint: boolean;
}): JSX.Element | null => {
  const user = useSelector((state: RootState) => state.userContext.user);
  const isVatRegistered = useSelector(
    (state: RootState) => state.userContext.resourceCentre.settings.billingSettings.vatRegistered
  );
  if (!billData || !billData.document) return null;
  const showInfo = showInfoColumn(billData);

  const billColumns = getBillColumns(showInfo).filter((header) => {
    if (header === BillColumns.DISCOUNT_AMT) {
      return !(
        [BillColumns.DISCOUNT_AMT].includes(header) &&
        billData.document?.settings.discountSettings.discountBasis === DiscountBasedOn.invoice
      );
    }
    if (header === BillColumns.VAT_PCT) {
      if (isVatRegistered) {
        return header;
      }
      return false;
    }
    return header;
  });

  const isBankPaper = forceTemplate === ForceTemplate.BANK_PAPER;
  const fontSize = "0.26cm";

  const showIndividualVAT =
    Object.keys(billData.document.summary.taxAmtWithRates || {}).filter(
      (vatPct) => Number(vatPct) !== 0
    ).length > 1;
  let netTotal;
  if (billData.document.type === DocumentTypes.CREDITNOTE) {
    netTotal = billData.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .add(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  } else {
    netTotal = billData.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .subtract(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  }

  return (
    <div>
      <div>
        <Box
          display="flex"
          borderTop="1px solid black"
          borderRight={relatedSettings.isBordered ? "1px solid black" : undefined}
          borderBottom={relatedSettings.isBordered ? "1px solid black" : undefined}
          borderLeft={relatedSettings.isBordered ? "1px solid black" : undefined}
          p="0.2cm"
          mt={1}
          className={!relatedSettings.isBordered ? "borderBotBlack1" : undefined}
          sx={
            relatedSettings.isBordered
              ? getSectionStyle(relatedSettings.headerColor).rawHeaderStyle
              : {}
          }
        >
          {billColumns.map((column, i) => (
            <Box
              mx={marginForNameColumn(column)}
              component="div"
              key={column}
              className={classNames({
                grow1: i === 0 || i === 2,
                flex3: i === 1 || (i === 2 && showInfo),
                flex1: i > 2
              })}
            >
              <Box
                textAlign={i > 2 ? "center" : "left"}
                style={{ textTransform: "uppercase" }}
                fontWeight={600}
                fontSize="0.23cm"
                whiteSpace="pre"
              >
                {tl(`billing.billItem.${column}`)}
              </Box>
            </Box>
          ))}
        </Box>

        <Box component="div" paddingBottom="0.45cm">
          {billData.document.billItems.map((item, index) => (
            <Box
              component="div"
              key={item.sNo}
              display="flex"
              style={
                relatedSettings.isBordered
                  ? {
                      pageBreakInside: "avoid"
                    }
                  : {}
              }
              borderTop={relatedSettings.isBordered && index !== 0 ? "0.5px solid lightgrey" : ""}
              borderBottom="1px solid #e0e0e0"
              className={
                index === billData.document.billItems.length - 1 &&
                !relatedSettings.isBordered &&
                "borderBotBlack1"
              }
            >
              {billColumns.map((column, i) => (
                <Box
                  borderLeft={`${i === 0 && relatedSettings.isBordered ? "1px solid black" : ""}`}
                  borderRight={`${
                    i === billColumns.length - 1 && relatedSettings.isBordered
                      ? "1px solid black"
                      : ""
                  }`}
                  paddingLeft={i === 0 ? "0.3cm" : ""}
                  paddingRight={i === billColumns.length - 1 ? "0.3cm" : ""}
                  component="div"
                  key={column}
                  className={classNames({
                    grow1: i === 0 || i === 2,
                    flex3: i === 1 || (i === 2 && showInfo),
                    flex1: i > 2
                  })}
                  paddingTop="0.2cm"
                  paddingBottom="0.2cm"
                  mx={marginForNameColumn(column)}
                >
                  <Box
                    sx={getColumnTextStyles({ index: i, column, fontSize: scaleFont("0.23cm", 1) })}
                  >
                    {["perUnit", "grossTotal"].includes(column) && item[column] !== null
                      ? Number(item[column]).toFixed(2)
                      : item[column]}
                    {showUnitAfterQuantity(column) && item.unit}
                    {column === "description" &&
                      item.serviceProviderId &&
                      `(${spFullNameSelector(spFromIdSelector(SPs, item.serviceProviderId))})`}
                  </Box>
                  {column === "description" && (
                    <Box sx={{ whiteSpace: "pre-wrap" }} fontSize={scaleFont("0.23cm", 0.89)}>
                      {item.batchInfo.batchId && `Batch ${item.batchInfo.batchId}`}
                      {item.batchInfo.expiryDate &&
                        `, Expiring ${moment(item.batchInfo.expiryDate).format("YYYY/MM/DD")}`}
                    </Box>
                  )}
                  {column === "description" &&
                    item?.subItems?.map((subitem) => (
                      <Box
                        key={subitem.productId}
                        paddingTop="0.05cm"
                        className={classNames("blockContent", { bankPaperFontSize: isBankPaper })}
                      >
                        {`◦ ${subitem.description}`}
                        {hasOwnProperty(subitem, "serviceProviderId") &&
                          subitem.serviceProviderId && (
                            <span style={{ marginLeft: "8px" }}>
                              {`(${spFullNameSelector(
                                spFromIdSelector(SPs, subitem.serviceProviderId)
                              )})`}
                            </span>
                          )}
                      </Box>
                    ))}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </div>
      <Box display="flex" className="blockContent">
        <Box width="60%" pr={0.5}>
          {Boolean(billData.document.remarks) && (
            <Box display="flex" alignItems="flex-start" gap={1}>
              <Typography fontSize={fontSize} flexShrink={0}>
                {tl("billing.remarks")}:
              </Typography>
              <Typography fontSize={fontSize} flexGrow={1}>
                {billData.document.remarks}
              </Typography>
            </Box>
          )}
          {!hidePaymentMethodOnPrint && Boolean(billData?.paymentInfo?.paymentMethod) && (
            <Box display="flex" alignItems="flex-start" gap={1}>
              <Typography fontSize={fontSize} flexShrink={0}>
                {tl("billing.paymentMethod")}:
              </Typography>
              <Typography fontSize={isBankPaper ? "0.23cm" : scaleFont("0.26cm")} flexGrow={1}>
                {startCase(billData.paymentInfo?.paymentMethod)}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="flex-start" gap={1}>
            <Typography fontSize={fontSize} flexShrink={0}>
              {tl("billing.inWords")}:
            </Typography>
            <Typography fontSize={fontSize} flexGrow={1}>
              {billData.document.summary.inWords} only
            </Typography>
          </Box>

          <Typography fontWeight={400} fontSize={scaleFont("0.23cm")} />

          <Box mt={2}>
            <Box display="flex" alignItems="flex-start" gap={1}>
              <Typography fontSize={fontSize} flexShrink={0}>
                {tl("billing.printedDateTime")}:
              </Typography>
              <Typography fontSize={fontSize} flexGrow={1}>
                {`${moment().format("YYYY/MM/DD")} ${moment().format("h:mm a")}`}
              </Typography>
            </Box>
            {billData?.printCount > 0 && (
              <Box display="flex" alignItems="flex-start" gap={1}>
                <Typography fontSize={fontSize} flexShrink={0}>
                  {tl("billing.rePrintUser")}:
                </Typography>
                <Typography fontSize={fontSize} flexGrow={1}>
                  {capitalizedName(`${user?.firstName} ${user?.lastName}`)}
                </Typography>
              </Box>
            )}
            {billData.document.enteredBy.name && (
              <Box display="flex" alignItems="flex-start" gap={1}>
                <Typography fontSize={fontSize} flexShrink={0}>
                  {tl("billing.billedBy")}:
                </Typography>
                <Typography fontSize={fontSize} flexGrow={1}>
                  {billData.document.enteredBy.name}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box width="40%">
          <Box paddingBottom="0.2cm" width="100%" textAlign="right">
            {Boolean(billData.document.summary.totalPerUnitTimesQty) && (
              <SummaryFieldBankPaper
                label={tl("billing.total")}
                value={billData.document.summary.totalPerUnitTimesQty}
              />
            )}
            {billData.document.summary.discount > 0 && (
              <SummaryFieldBankPaper
                label={tl("billing.discount")}
                value={billData.document.summary.discount || 0}
              />
            )}

            {showIndividualVAT &&
              Object.keys(billData.document.summary.taxAmtWithRates || {})
                .filter((vatPct) => Number(vatPct) !== 0)
                .map((vatPct, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box key={index} marginTop="0.1cm">
                    <SummaryFieldBankPaper
                      label={`${tl("billing.VAT")} ${vatPct}%`}
                      value={billData.document.summary.taxAmtWithRates[vatPct]}
                    />
                  </Box>
                ))}
            {netTotal > 0 && (
              <Box marginTop="0.1cm">
                <SummaryFieldBankPaper label={tl("billing.taxableAmount")} value={netTotal} />
              </Box>
            )}
            {Boolean(billData.document.summary.taxAmount) && (
              <SummaryFieldBankPaper label="VAT 13%" value={billData.document.summary.taxAmount} />
            )}
          </Box>

          <Box width="100%" textAlign="right">
            <SummaryFieldBankPaper
              labelStyle={{ fontSize }}
              valueStyle={{ fontSize }}
              label={tl("billing.grandTotal")}
              value={rupeeDisplay(billData.document.summary.totalAmount.toFixed(2))}
            />
            {clientBalance > 0 && (
              <SummaryFieldBankPaper
                labelStyle={{ fontSize }}
                valueStyle={{ fontSize }}
                label={t("billing.advanceRemaining")}
                value={rupeeDisplay(clientBalance.toFixed(2))}
              />
            )}
            {billData?.document?.paymentInfo?.paymentDistribution?.paymentFromSsf && (
              <SummaryFieldBankPaper
                labelStyle={{ fontSize }}
                valueStyle={{ fontSize }}
                label={t("billing.paymentFromSSF")}
                value={rupeeDisplay(
                  billData.document.paymentInfo.paymentDistribution.paymentFromSsf
                )}
              />
            )}
            {billData.paymentInfo?.paidAmount > 0 && (
              <SummaryFieldBankPaper
                labelStyle={{ fontSize }}
                valueStyle={{ fontSize }}
                label={t("reports.paidAmount")}
                value={round(billData.paymentInfo?.paidAmount, 2)}
              />
            )}
            {!billData.paymentInfo?.paid && (
              <SummaryFieldBankPaper
                labelStyle={{ fontSize }}
                valueStyle={{ fontSize }}
                label={t("reports.dueAmount")}
                value={round(
                  chain(billData.summary?.totalAmount)
                    .subtract(billData.paymentInfo?.paidAmount || 0)
                    .done(),
                  2
                )}
              />
            )}
            {!!billData.paymentInfo?.tenderAmount && (
              <>
                <SummaryFieldBankPaper
                  labelStyle={{ fontSize }}
                  valueStyle={{ fontSize }}
                  label={t("billing.tenderAmount")}
                  value={rupeeDisplay(billData.paymentInfo.tenderAmount.toFixed(2))}
                />
                <SummaryFieldBankPaper
                  labelStyle={{ fontSize }}
                  valueStyle={{ fontSize }}
                  label={t("billing.changeAmount")}
                  value={rupeeDisplay(billData.paymentInfo.changeAmount.toFixed(2))}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default BillDescriptionBankPaper;
