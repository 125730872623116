import React from "react";
import { Box, Typography } from "@mui/material";
import Barcode from "react-barcode";
import { getSettingBasedDate, scaleFont } from "../LabPrintFunctions";
import { clientFullNameSelector } from "../../../../reducers/client";
import { t } from "../../../../components/translate";
import { LabObjectClient } from "../../../../interfaces/LabInterfaces";
import { showDOBAndGender } from "../../../Billing/PrintBill/EightyMmPrint/ClientInfo";
import LabGovPrintInfoField from "./LabGovPrintInfoField";
import { getCustomerNumber } from "../../../Client/ClientList";
import { IPD } from "../../../../interfaces/IpdInterface";
/* eslint-disable react/require-default-props */

interface ClientInfoGovProps {
  client: LabObjectClient;
  customerNumber?: number;
  labId: number;
  ipd: IPD;
  showDobOnLabPrint?: boolean;
}

const LabPrintClientInfoGov: React.FC<ClientInfoGovProps> = ({
  client,
  customerNumber,
  labId,
  ipd,
  showDobOnLabPrint
}) => (
  <Box flexGrow={1}>
    <Typography
      sx={{
        fontWeight: 500,
        textAlign: "left",
        fontSize: scaleFont("0.40cm", 1),
        paddingBottom: "4px",
        textTransform: "uppercase"
      }}
    >
      {clientFullNameSelector(client)}
      <span style={{ marginLeft: "5px" }}>{showDOBAndGender(client.gender, client.dob, true)}</span>
    </Typography>
    {customerNumber && (
      <LabGovPrintInfoField
        label={t("labPrint.patientID")}
        data={<>{getCustomerNumber(customerNumber?.toString())}</>}
      />
    )}
    {ipd?.ipdNumber && <LabGovPrintInfoField label="Ipd No." data={<>{ipd.ipdNumber}</>} />}
    {ipd?.bed && <LabGovPrintInfoField label="Bed No." data={<>{ipd?.bed?.name}</>} />}
    {showDobOnLabPrint && client && client.dob && (
      <LabGovPrintInfoField
        label="Date of Birth"
        data={<>{getSettingBasedDate("BS", client.dob)}</>}
      />
    )}
    {client.phone && (
      <LabGovPrintInfoField
        label={t("billing.phoneNo")}
        data={
          <>{client.phone?.substring(0, 4) === "+977" ? client.phone.substring(4) : client.phone}</>
        }
      />
    )}
    <Barcode
      value={labId}
      width={2}
      displayValue={false}
      fontSize={5}
      height={20}
      margin={2}
      font="arial"
      renderer="img"
    />
  </Box>
);

export default LabPrintClientInfoGov;
