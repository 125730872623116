import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import produce from "immer";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import styles from "./styles.module.css";
import hasOwnProperty from "../../helpers/object";

const { useState } = React;

interface Props {
  maxColCount: number;
  onChange: (v: TableState) => void;
  initValue?: TableState;
  hideControls: boolean;
  hideLabel?: boolean;
}

export interface TableState {
  title: string;
  nodes: Array<Array<Node>>;
  show?: boolean;
}

interface Node {
  x: number;
  y: number;
  data: string;
}

export const getInitialState = (count: number): TableState => {
  const tableState = {} as TableState;
  tableState.title = "Results";
  tableState.nodes = [];
  const headers: Array<Node> = [];
  [...new Array(count)].forEach((_, i) =>
    headers.push({
      x: i,
      y: 0,
      data: `Header ${i}`
    })
  );
  tableState.nodes.push(headers);
  for (let j = 0; j < count; j += 1) {
    const row: Array<Node> = [];
    [...new Array(count)].forEach((_, i) =>
      row.push({
        x: i,
        y: j + 1,
        data: `row ${j + 1} ${i}`
      })
    );
    tableState.nodes.push(row);
  }
  return tableState;
};

export function filterEmptyTableData(table: TableState, isForPreview?: boolean): TableState | null {
  // returns table with only the nodes that have at least one cell filled
  const filteredData = produce(table, (draft) => {
    draft.nodes = draft.nodes.filter((item) => item.some((node) => !!node.data));
  });
  const validNodeLength = isForPreview ? 0 : 1;
  // table header data is always present, so array length will always be at least 1
  if (filteredData.nodes.length > validNodeLength) return filteredData;

  return null;
}

// get table cell/node data using x and y value
const getCurrentNodeData = (node: Node[][], x: number, y: number): Node | null => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < node.length; i++) {
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < node[i].length; j++) {
      if (node[i][j].x === x && node[i][j].y === y) {
        return node[i][j];
      }
    }
  }
  return null;
};

export default function EditableTable({
  maxColCount,
  onChange,
  initValue,
  hideControls,
  hideLabel = false
}: Props): JSX.Element {
  const [state, setState] = useState<TableState>(initValue || getInitialState(2));

  React.useEffect(() => {
    if (initValue) setState(initValue);
  }, [initValue]);

  const updateState = (updatedState: TableState) => {
    setState(updatedState);
    onChange(updatedState);
  };

  function deleteColumn(idx: number) {
    const updatedState = produce(state, (draft) => {
      draft.nodes.forEach((row) => {
        row.splice(idx, 1);
      });
      draft.nodes = draft.nodes.filter(Boolean);
    });
    updateState(updatedState);
  }

  function deleteRow(idx: number) {
    const updatedState = produce(state, (draft) => {
      draft.nodes.splice(idx, 1);
    });
    updateState(updatedState);
  }

  function addRow() {
    const updatedState = produce(state, (draft) => {
      const row: Array<Node> = [...new Array(state.nodes[0].filter(Boolean).length)].map(
        (_, i) => ({
          y: state.nodes.length,
          x: i,
          data: ""
        })
      );
      draft.nodes.push(row);
    });
    updateState(updatedState);
  }

  function addColumn() {
    const updatedState = produce(state, (draft) => {
      draft.nodes.forEach((row, i) => {
        row.push({
          x: row.length,
          y: i,
          data: ""
        });
      });
    });
    updateState(updatedState);
  }

  function handleNodeUpdate(node: Node, value: string) {
    const updatedState = produce(state, (draft) => {
      const relatedNode = getCurrentNodeData(draft.nodes, node.x, node.y);
      if (relatedNode) {
        relatedNode.data = value;
      }
    });
    updateState(updatedState);
  }

  return (
    <div>
      {!hideLabel && (
        <TextField
          sx={{
            marginLeft: hideControls ? "0px" : "32px",
            marginTop: hideControls ? "0px" : "32px",
            marginBottom: "4px"
          }}
          label="Table label"
          variant="outlined"
          defaultValue={state.title}
          onBlur={(e) => {
            updateState({ ...state, title: e.target.value });
          }}
        />
      )}
      {!hideControls && (
        <Box ml="32px" mt="16px">
          <Button onClick={() => addRow()} variant="outlined">
            Add Row
          </Button>
          {state.nodes[0].filter(Boolean).length < maxColCount && (
            <Button onClick={() => addColumn()} variant="outlined" sx={{ marginLeft: "16px" }}>
              Add Column
            </Button>
          )}
        </Box>
      )}
      {!hideControls && (
        <Box display="flex" ml="32px" mt="8px">
          {state.nodes[0].map((el, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              minWidth="100px"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton onClick={() => deleteColumn(index)}>
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
      {state.nodes.map((row, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box display="flex" key={idx}>
          {!hideControls && idx !== 0 && (
            <Box
              width="32px"
              height="40px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton onClick={() => deleteRow(idx)}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <Box
            display="flex"
            ml={!hideControls && idx === 0 ? "32px" : "0"}
            width="100%"
            className={styles.container}
          >
            {row.map((node, index) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                width="100%"
                minWidth="100px"
                overflow="auto"
                minHeight="40px"
                border="1px solid lightgrey"
                display="flex"
                pl="4px"
                bgcolor={idx === 0 ? "#f9f9f9" : "#fff"}
                alignItems="center"
                className={styles.node}
              >
                <TextField
                  fullWidth
                  defaultValue={node.data}
                  onFocus={(e) => e.target.select()}
                  InputProps={{ disableUnderline: true }}
                  onBlur={(e) => handleNodeUpdate(node, e.target.value)}
                />
                <EditIcon fontSize="small" color="action" className={styles.editIcon} />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </div>
  );
}

export function EditableTableView({
  tableState,
  isForPreview = true
}: {
  isForPreview?: boolean;
  tableState: TableState;
}): JSX.Element | null {
  if (
    !tableState?.nodes?.flat()?.length ||
    (hasOwnProperty(tableState, "show") && !tableState.show)
  ) {
    return null;
  }
  const filteredTableData = filterEmptyTableData(tableState, isForPreview) || ({} as TableState);

  const headerBgColor = isForPreview ? "lightgrey" : "#f9f9f9";

  return (
    <Box my="8px">
      <Typography fontWeight={600} gutterBottom>
        {isForPreview ? `${filteredTableData.title}` : `${filteredTableData.title}:`}
      </Typography>
      <TableContainer>
        <Table sx={{ display: "flex" }} size="small" aria-label="Result table">
          <TableBody>
            {filteredTableData.nodes?.map((row, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={idx} sx={{ width: "100%" }}>
                {row?.map((node, index) => (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    pl="4px"
                    bgcolor={idx === 0 ? headerBgColor : "#fff"}
                    className={styles.node}
                  >
                    {node?.data}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
