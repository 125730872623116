import { Box, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { notificationAdd } from "../../actions/notification";
import { getClients } from "../../api/reports";
import { CalendarFunctions } from "../../components/Calendar";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { tl } from "../../components/translate";
import { downloadCSV } from "../../helpers/files";
import { ageFormatter } from "../../helpers/formatters";
import { commonErrorMessage } from "../../helpers/messages";
import useMobileScreen from "../../hooks/useMobileScreen";
import { Client } from "../../interfaces/ClientInterface";
import { getClientGender } from "../Assessment/AssessmentClientSelect";
import { getGenderCode } from "../Billing/PrintBill/EightyMmPrint/ClientInfo";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import Filters, { FilterProps, SHOW_CLIENT_BY } from "./Filters";
import "./Reports.scss";
import {
  clientReportDefaultCols,
  clientReportHiddenCols,
  getDefaultKeyValuesColumns
} from "../../components/ListHeaderShowHideDialog/helpers";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";

const docColumns = [
  "Client Id",
  "Customer Number",
  "Client Name",
  "Age",
  "Gender",
  "Phone",
  "Email",
  "National Identity Number",
  "Address",
  "Last Visited Date",
  "knownUsFrom",
  "bloodGroup",
  "city",
  "ethnicity",
  "maritalStatus",
  "nationality",
  "occupation",
  "insuranceNumber",
  "internalNotes"
];

// New client is client which is just created.
// Old client is client which has different day for created_at and lastVisited
enum ClientTypeValue {
  NEW = "New",
  OLD = "Old"
}
const getClientTypeValue = (createdAt, lastVisitedAt) => {
  if (!lastVisitedAt || moment(createdAt).isSame(moment(lastVisitedAt), "day")) {
    return ClientTypeValue.NEW;
  }
  return ClientTypeValue.OLD;
};

const docRowProcessor = ({
  id,
  customerNumber,
  firstName,
  lastName,
  dob,
  gender,
  phone,
  email,
  address,
  lastVisitedAt,
  knownUsFrom,
  bloodGroup,
  city,
  ethnicity,
  maritalStatus,
  nationality,
  occupation,
  insuranceNumber,
  internalNotes,
  nationalIdentityNumber
}) => [
  id,
  customerNumber,
  `${firstName} ${lastName}`,
  dob ? ageFormatter(dob) : "",
  getClientGender(gender),
  phone,
  email,
  nationalIdentityNumber,
  address,
  lastVisitedAt ? convertADtoBS(new Date(lastVisitedAt)).formatted3 : "",
  knownUsFrom,
  bloodGroup,
  city,
  ethnicity,
  maritalStatus,
  nationality,
  occupation,
  insuranceNumber,
  internalNotes
];

export default function ClientReport(): JSX.Element {
  const [filters, setFilters] = React.useState<FilterProps>({
    from: CalendarFunctions.startOfDay(new Date()).toDate(),
    until: new Date(),
    clientIds: [],
    showClientByDate: SHOW_CLIENT_BY.LAST_VISITED_DATE,
    knownUsFrom: ""
  });
  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(clientReportDefaultCols, clientReportHiddenCols)
  );
  const dispatch = useDispatch();
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [isColumnDialogOpen, setIsColumnDialogOpen] = React.useState(false);

  const [reportData, setReportData] = React.useState<Client[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        setIsRequestLoading(true);
        const res = await getClients(filters);
        setReportData(res);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: error?.message || commonErrorMessage,
            autoTimeout: true,
            variant: "error"
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [filters]);

  const isMobileScreen = useMobileScreen();
  const [selectedClient, setSelectedClient] = React.useState<Client>({} as Client);
  const [clientEditMode, setClientEditMode] = React.useState<boolean>(false);
  const handleViewClose = () => setSelectedClient({} as Client);

  return (
    <Box mt={4} overflow="auto hidden">
      <Box minWidth={1000}>
        <Box margin="0px 32px">
          <Filters
            filters={filters}
            onSetFilters={(updatedFilters) => setFilters(updatedFilters)}
            isRequestLoading={isRequestLoading}
          />
        </Box>
        <Box width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}>
          <Box
            paddingTop="32px"
            width={isMobileScreen ? "960px" : "auto"}
            height="calc(100vh - 230px)"
            className="clientReportList"
          >
            <List
              automation="clientReportList"
              data={reportData}
              rowHeight={50}
              defaultSortColumn="referrers"
              defaultSortOrder={-1}
              activeRow={1}
              adjustHeightToContents
              columns={[
                {
                  key: "id",
                  label: "Client Id",
                  sortable: true,
                  sortFunction: (a, b) => (a.id > b.id ? 1 : -1),
                  formatter: ({ id }) => <Typography>{id}</Typography>
                },
                {
                  key: "customerNumber",
                  label: "Customer No.",
                  sortable: true,
                  formatter: ({ customerNumber }) => <Typography>{customerNumber || ""}</Typography>
                },
                {
                  key: "clientName",
                  label: tl("name"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.clientName?.toLowerCase() > b.clientName?.toLowerCase() ? 1 : -1,
                  formatter: (row) => (
                    <Box
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        width: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedClient(row);
                      }}
                    >
                      {`${row.firstName} ${row.lastName}`.toUpperCase()}
                    </Box>
                  )
                },
                {
                  key: "dob",
                  label: "DOB",
                  sortable: true,
                  sortFunction: (a, b) => (new Date(a.dob) > new Date(b.dob) ? 1 : -1),
                  formatter: ({ dob }) => (
                    <Typography justifyContent="center" alignItems="center">
                      {dob ? convertADtoBS(moment.utc(dob)).formatted4 : ""}
                    </Typography>
                  )
                },
                {
                  key: "age",
                  label: "Age",
                  sortable: true,
                  formatter: ({ dob }) => (
                    <Typography justifyContent="center" alignItems="center">
                      {dob ? ageFormatter(dob) : ""}
                    </Typography>
                  )
                },
                {
                  key: "gender",
                  label: "Gender",
                  sortable: true,
                  sortFunction: (a, b) => (a.gender > b.gender ? 1 : -1),
                  formatter: ({ gender }) => (
                    <Typography justifyContent="center" alignItems="center">
                      {getGenderCode(gender)}
                    </Typography>
                  )
                },
                {
                  key: "phone",
                  label: "Phone",
                  formatter: ({ phone }) => (
                    <Typography flex={1} flexDirection="column" alignItems="center">
                      {phone}
                    </Typography>
                  )
                },
                {
                  key: "bloodGroup",
                  label: "Blood Group",
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.bloodGroup?.toLowerCase() > b.bloodGroup?.toLowerCase() ? 1 : -1,
                  formatter: ({ bloodGroup }) => <Typography>{bloodGroup}</Typography>
                },
                {
                  key: "city",
                  label: "City",
                  sortable: true,
                  sortFunction: (a, b) => (a.city?.toLowerCase() > b.city?.toLowerCase() ? 1 : -1),
                  formatter: ({ city }) => <Typography>{city}</Typography>
                },
                {
                  key: "ethnicity",
                  label: "Ethnicity",
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.ethnicity?.toLowerCase() > b.ethnicity?.toLowerCase() ? 1 : -1,
                  formatter: ({ ethnicity }) => <Typography>{ethnicity}</Typography>
                },
                {
                  key: "maritalStatus",
                  label: "Marital Status",
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.maritalStatus?.toLowerCase() > b.maritalStatus?.toLowerCase() ? 1 : -1,
                  formatter: ({ maritalStatus }) => <Typography>{maritalStatus}</Typography>
                },
                {
                  key: "nationality",
                  label: "Nationality",
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.nationality?.toLowerCase() > b.nationality?.toLowerCase() ? 1 : -1,
                  formatter: ({ nationality }) => <Typography>{nationality}</Typography>
                },
                {
                  key: "occupation",
                  label: "Occupation",
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.occupation?.toLowerCase() > b.occupation?.toLowerCase() ? 1 : -1,
                  formatter: ({ occupation }) => <Typography>{occupation}</Typography>
                },
                {
                  key: "insuranceNumber",
                  label: "Insurance Number",
                  formatter: ({ insuranceNumber }) => <Typography>{insuranceNumber}</Typography>
                },
                {
                  key: "nationalIdentityNumber",
                  label: "National Identity Number",
                  formatter: ({ nationalIdentityNumber }) => (
                    <Typography>{nationalIdentityNumber || "N/A"}</Typography>
                  )
                },
                {
                  key: "internalNotes",
                  label: "Internal Notes",
                  formatter: ({ internalNotes }) => <Typography>{internalNotes}</Typography>
                },
                {
                  key: "email",
                  label: "Email",
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.email?.toLowerCase() > b.email?.toLowerCase() ? 1 : -1,
                  formatter: ({ email }) => <Typography>{email}</Typography>
                },
                {
                  key: "knownUsFrom",
                  label: "Known Us From",
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.knownUsFrom?.toLowerCase() > b.knownUsFrom?.toLowerCase() ? 1 : -1,
                  formatter: ({ knownUsFrom }) => <Typography>{knownUsFrom}</Typography>
                },
                {
                  key: "address",
                  label: "Address",
                  sortable: true,
                  sortFunction: (a, b) =>
                    a.address?.toLowerCase() > b.address?.toLowerCase() ? 1 : -1,
                  formatter: ({ address }) => <Typography>{address}</Typography>
                },
                {
                  key: "lastVisitedAt",
                  label: "Last Visited At",
                  sortable: true,
                  sortFunction: (a, b) =>
                    new Date(a.lastVisitedAt) > new Date(b.lastVisitedAt) ? 1 : -1,
                  formatter: ({ lastVisitedAt }) => (
                    <Typography>
                      {lastVisitedAt ? convertADtoBS(new Date(lastVisitedAt)).formatted3 : ""}
                    </Typography>
                  )
                },
                {
                  key: "clientType",
                  label: "Client Type",
                  sortable: true,
                  sortFunction: (a, b) =>
                    new Date(a.lastVisitedAt) > new Date(b.lastVisitedAt) ? 1 : -1,
                  formatter: ({ lastVisitedAt, createdAt }) => (
                    <Typography>
                      {filters.showClientByDate === SHOW_CLIENT_BY.CREATED_DATE
                        ? ClientTypeValue.NEW
                        : getClientTypeValue(createdAt, lastVisitedAt)}
                    </Typography>
                  )
                }
              ].filter((row) => listColumns[row.key])}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () =>
                        downloadCSV(
                          `Client Report - ${convertADtoBS(filters.from).formatted}-${
                            convertADtoBS(filters.until).formatted
                          }`,
                          getProcessedData(),
                          docColumns,
                          (row) => docRowProcessor(row)
                        )
                      }
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem onClick={() => setIsColumnDialogOpen(true)}>
                      Show/Hide Columns
                    </MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              requiredColumns={["id", "clientName"]}
              open={isColumnDialogOpen}
              onClose={() => setIsColumnDialogOpen(false)}
              columns={listColumns}
            />
            {!isEmpty(selectedClient) &&
              (clientEditMode ? (
                <ClientCreateEdit
                  setEditMode={setClientEditMode}
                  clientId={selectedClient.id}
                  mode="edit"
                  onCancel={() => {
                    setClientEditMode(false);
                  }}
                  stayOnCurrentPage
                />
              ) : (
                <ClientInfo
                  id={selectedClient.id}
                  handleViewClose={handleViewClose}
                  stayOnCurrentPage
                  setEditMode={setClientEditMode}
                />
              ))}
          </Box>
          <Box className="totalBar">
            <Box
              sx={{
                display: "flex",
                px: 3,
                justifyContent: "space-between"
              }}
            >
              {tl("reports.totalClients")}
            </Box>
            <Typography>{reportData.length}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
