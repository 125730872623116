import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import capitalize from "lodash/capitalize";
import * as moment from "moment";
import { useState } from "react";
import { tl, t } from "../../components/translate";
import Filters from "./Filters";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import * as reportsApi from "../../api/reports";
import { downloadCSV } from "../../helpers/files";
import useMobileScreen from "../../hooks/useMobileScreen";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import Can from "../Policy/Can";
import { RootState } from "../../store";
import "./Reports.scss";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { AssessmentReportData, Medicine } from "../../interfaces/AssessmentInterfaces";
import {
  getDefaultKeyValuesColumns,
  patientAssessmentDefaultCols,
  patientAssessmentHiddenCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import { notificationAdd } from "../../actions/notification";
import { commonErrorMessage } from "../../helpers/messages";
import { ReportPrint } from "./Print/ReportPrint";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import AssessmentHistoryPanel from "../../components/AssessmentHistoryPanel/Index";
import { getCustomerNumber } from "../Client/ClientList";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

enum PatientAssessmentKeys {
  NAME = "name",
  CUSTOMER_NUMBER = "customerNumber",
  CLIENT = "client",
  CLIENT_FIRST_NAME = "clientFirstName",
  CLIENT_LAST_NAME = "clientLastName",
  CLIENT_PHONE = "clientPhone",
  CLIENT_SECONDARY_PHONE = "clientSecondaryPhone",
  CLIENT_EMAIL = "clientEmail",
  CLIENT_DOB = "clientDOB",
  CLIENT_GENDER = "clientGender",
  ADDRESS = "address",
  SERVICE_PROVIDER = "serviceProvider",
  PREPARED_BY = "preparedBy",
  OPD_DURATION = "opdDuration",
  DATE = "date",
  SYMPTOMS = "symptoms",
  ATTACHMENT_COUNT = "attachmentCount",
  STATUS = "status",
  HOPI = "hopi",
  DIAGNOSIS = "diagnosis",
  LAB_REPORTS = "labReports",
  MEDICATION = "medication",
  MEDICATION_REMARK = "medicationRemark",
  PAST_HISTORY_OF_ALLERGY = "pastHistoryOfAllergy",
  INVESTIGATION = "investigation",
  PRESENT_HEALTH_STATUS = "presentHealthStatus",
  TREATMENT_PLAN = "treatmentPlan",
  TREATMENT_GIVEN = "treatmentGiven",
  ADVICE = "advice",
  OTHERS = "others",
  DEPARTMENT = "department"
}

export const spNameFormatter = (serviceProvider: ServiceProvider): string => {
  const { title = "", firstName = "", lastName = "" } = serviceProvider || {};
  return `${capitalize(title)}${title ? " " : ""}${capitalize(firstName)} ${capitalize(lastName)}`;
};
const clientNameFormatter = ({
  firstName,
  lastName
}: {
  firstName: string;
  lastName: string;
}): string => `${capitalize(firstName)} ${capitalize(lastName)}`;
export const diagnosisFormatter = ({ diagnosis }: { diagnosis: string }): string =>
  `${diagnosis || ""}`;
export const symptomsFormatter = ({
  symptoms = []
}: {
  symptoms: { code: string; symptom: string }[];
}): string => symptoms.map(({ symptom }) => symptom).join(", ");

export const opdDurationFormatter = (time: number): string =>
  time ? moment.utc(time).format("H [hrs] m [mins]") : "-";

const testsFormatter = ({ tests = [] }) => tests.map(({ test }) => test).join(", ");
export const medicationFormatter = ({ medication = [] }: { medication: Medicine[] }): string =>
  (medication || []).map(({ brand }) => brand).join(", ");
export const medicationRemarkFormatter = ({
  medication = []
}: {
  medication: Medicine[];
}): string =>
  (medication || [])
    .filter(({ remark = "" }) => remark.length !== 0)
    .map(({ remark }) => remark)
    .join(", ");
const preparedByFormatter = ({ preparedBy }) => `${preparedBy?.firstName} ${preparedBy?.lastName}`;
const or = (val) => val || "";

// For Excel download headers
const docColumns = () => [
  t("reports.customerNumber"),
  t("reports.client"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("reports.clientPhone"),
  t("reports.clientSecondaryPhone"),
  t("reports.clientEmail"),
  t("reports.clientDOB"),
  t("reports.clientGender"),
  t("clients.address"),
  t("reports.department"),
  t("reports.serviceProvider"),
  t("reports.preparedBy"),
  t("reports.opdDuration"),
  t("reports.date"),
  t("reports.symptoms"),
  t("reports.attachmentCount"),
  t("reports.status"),
  t("reports.hopi"),
  t("reports.diagnosis"),
  t("reports.labReports"),
  t("reports.medication"),
  t("reports.medicationRemark"),
  t("reports.pastHistoryOfAllergy"),
  t("reports.investigation"),
  t("reports.presentHealthStatus"),
  t("reports.treatmentPlan"),
  t("reports.treatmentGiven"),
  t("reports.advice"),
  t("reports.others")
];

// For Excel download values
const docRowProcessor = ({
  customerNumber,
  clientFirstName,
  clientLastName,
  clientCity,
  clientPhone,
  secondaryPhone,
  clientEmail,
  clientDOB,
  attachmentCount,
  clientGender,
  department,
  clientAddress,
  assessmentDocument,
  // eslint-disable-next-line camelcase
  createdDate,
  // eslint-disable-next-line camelcase
  __meta__row_type
}) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type !== "segment_summary") {
    const dob = clientDOB ? calFns.bsShortDate(clientDOB) : "";
    return [
      getCustomerNumber(customerNumber),
      clientNameFormatter({ firstName: clientFirstName, lastName: clientLastName }),
      or(clientFirstName),
      or(clientLastName),
      or(clientPhone),
      or(secondaryPhone),
      or(clientEmail),
      dob,
      clientGender ? t(`clients.gender.${clientGender}`) : "",
      `${or(clientAddress)} ${or(clientCity)}`,
      department?.name || "-",
      spNameFormatter(assessmentDocument.serviceProvider),
      preparedByFormatter(assessmentDocument),
      opdDurationFormatter(assessmentDocument.opdDuration),
      calFns.bsShortDate(createdDate),
      symptomsFormatter(assessmentDocument),
      attachmentCount,
      or(assessmentDocument.assessment),
      or(assessmentDocument.hopi),
      diagnosisFormatter(assessmentDocument.diagnosis),
      testsFormatter(assessmentDocument),
      medicationFormatter(assessmentDocument),
      medicationRemarkFormatter(assessmentDocument),
      assessmentDocument.pastHistoryOfAllergy,
      assessmentDocument.investigation,
      assessmentDocument.presentHealthStatus,
      assessmentDocument.treatmentPlan,
      assessmentDocument.treatmentGiven,
      assessmentDocument.advice,
      or(assessmentDocument.others)
    ];
  }
  return [];
};

// For Print Values
const formatter = (
  {
    clientFirstName,
    clientEmail,
    clientLastName,
    createdDate,
    clientPhone,
    clientGender,
    assessmentDocument,
    others
  },
  filteredHeaders
) =>
  [
    { key: PatientAssessmentKeys.NAME, value: `${clientFirstName} ${clientLastName}` },
    { key: PatientAssessmentKeys.CLIENT_PHONE, value: clientPhone },
    { key: PatientAssessmentKeys.CLIENT_GENDER, value: clientGender },
    { key: PatientAssessmentKeys.CLIENT_EMAIL, value: clientEmail },
    {
      key: PatientAssessmentKeys.SERVICE_PROVIDER,
      value: spNameFormatter(assessmentDocument.serviceProvider)
    },
    { key: PatientAssessmentKeys.DATE, value: `${convertADtoBS(moment(createdDate)).formatted2}` },
    { key: PatientAssessmentKeys.SYMPTOMS, value: symptomsFormatter(assessmentDocument) },
    {
      key: PatientAssessmentKeys.OPD_DURATION,
      value: opdDurationFormatter(assessmentDocument.opdDuration)
    },
    {
      key: PatientAssessmentKeys.DIAGNOSIS,
      value: diagnosisFormatter(assessmentDocument.diagnosis)
    },
    { key: PatientAssessmentKeys.MEDICATION, value: medicationFormatter(assessmentDocument) },
    {
      key: PatientAssessmentKeys.MEDICATION_REMARK,
      value: medicationRemarkFormatter(assessmentDocument)
    },
    {
      key: PatientAssessmentKeys.PAST_HISTORY_OF_ALLERGY,
      value: assessmentDocument?.pastHistoryOfAllergy
    },
    { key: PatientAssessmentKeys.HOPI, value: assessmentDocument?.hopi },
    { key: PatientAssessmentKeys.STATUS, value: assessmentDocument?.assessment },
    { key: PatientAssessmentKeys.INVESTIGATION, value: assessmentDocument?.investigation },
    {
      key: PatientAssessmentKeys.PRESENT_HEALTH_STATUS,
      value: assessmentDocument?.presentHealthStatus
    },
    { key: PatientAssessmentKeys.TREATMENT_PLAN, value: assessmentDocument?.treatmentPlan },
    { key: PatientAssessmentKeys.TREATMENT_GIVEN, value: assessmentDocument?.treatmentGiven },
    { key: PatientAssessmentKeys.ADVICE, value: assessmentDocument?.advice },
    { key: PatientAssessmentKeys.OTHERS, value: others }
  ]
    .filter((row) => filteredHeaders.includes(row.key))
    .map((row) => row.value);

const getPrintData = (headers, data) => [
  headers.map((h) => startCase(h)),
  ...data
    // eslint-disable-next-line no-underscore-dangle
    .filter((v) => v.__meta__row_type !== "segment_summary")
    .map((item) => formatter(item, headers))
];

const PatientFlow = ({ serviceProviderId }) => {
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    serviceProviderIds: serviceProviderId ? [serviceProviderId] : [],
    clientIds: []
  });

  const [reportData, setReportData] = React.useState<AssessmentReportData[]>([]);
  const dispatch = useDispatch();
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);

  React.useEffect(() => {
    const getAssessmentData = async () => {
      try {
        setIsRequestLoading(true);
        const assessmentData = await reportsApi.assessmentReport(filters);
        setReportData(assessmentData);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: error?.message || commonErrorMessage,
            variant: "error",
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    };
    getAssessmentData();
  }, [filters, dispatch]);

  const applyFilters = (newfilters) => {
    setFilters(newfilters);
  };

  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState<number | null>(null);
  const [showHistoryPanel, setShowHistoryPanel] = useState(false);
  const handleViewClose = () => {
    setSelectedClientId(null);
    setClientInfoPanel(false);
  };

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(patientAssessmentDefaultCols, patientAssessmentHiddenCols)
  );
  const [open, setOpen] = React.useState(false);

  const [clientEditMode, setClientEditMode] = React.useState(false);
  const isMobileScreen = useMobileScreen();

  const filteredHeaders = [...patientAssessmentDefaultCols, ...patientAssessmentHiddenCols].filter(
    (h) => listColumns[h]
  );
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:patientAssessmentReport">
      <Box mt={4} overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1000px" : "auto"}>
          <Box margin="0px 32px">
            <Filters
              filters={filters}
              onSetFilters={(currFilters) => applyFilters(currFilters)}
              isRequestLoading={isRequestLoading}
            />
          </Box>
          <Box sx={{ overflowX: "auto", mx: "auto" }}>
            <Box
              marginTop="32px"
              className="patientAssessmentsReportList"
              width={isMobileScreen ? "960px" : "auto"}
            >
              <List
                automation="patientAssessmentsReportList"
                data={reportData}
                rowHeight={50}
                defaultSortColumn="date"
                defaultSortOrder={-1}
                adjustHeightToContents
                onRowClick={(row) => {
                  if (row.clientId === selectedClientId) {
                    setShowHistoryPanel(false);
                    setSelectedClientId(null);
                  } else {
                    setShowHistoryPanel(true);
                    setSelectedClientId(row.clientId);
                  }
                  setClientInfoPanel(false);
                }}
                columns={[
                  {
                    key: PatientAssessmentKeys.NAME,
                    label: tl("reports.client"),
                    sortable: true,
                    // sortable: true, @ TODO apply sortable and make sure it works
                    sortFunction: (a, b) =>
                      clientNameFormatter(a.assessmentDocument.client).toLowerCase() >
                      clientNameFormatter(b.assessmentDocument.client).toLowerCase()
                        ? 1
                        : -1,
                    formatter: (row) => (
                      <Typography
                        component="div"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start"
                        }}
                      >
                        <Box
                          style={{
                            textDecoration: `${row.active ? "underline" : ""}`,
                            cursor: "pointer",
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (row.active) {
                              setSelectedClientId(row.clientId);
                              setClientInfoPanel(true);
                              setShowHistoryPanel(false);
                            }
                          }}
                        >
                          {`${row.clientFirstName} ${row.clientLastName}`.toUpperCase()}
                          {row.active ? "" : "(deleted)"}
                        </Box>
                        <Box fontSize="small" color="gray">
                          {getCustomerNumber(row.customerNumber)}
                        </Box>
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.CLIENT_PHONE,
                    label: "Client Phone",
                    sortable: true,
                    formatter: ({ clientPhone }) => (
                      <Typography overflow="hidden">{clientPhone}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.CLIENT_GENDER,
                    label: "Client Gender",
                    sortable: true,
                    formatter: ({ clientGender }) => (
                      <Typography overflow="hidden">{clientGender}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.CLIENT_EMAIL,
                    label: "Client Email",
                    sortable: true,
                    formatter: ({ clientEmail }) => (
                      <Typography overflow="hidden">{clientEmail}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.SERVICE_PROVIDER,
                    label: tl("reports.serviceProvider"),
                    sortable: true,
                    sortFunction: (a, b) =>
                      spNameFormatter(a.assessmentDocument.serviceProvider).toLowerCase() >
                      spNameFormatter(b.assessmentDocument.serviceProvider).toLowerCase()
                        ? 1
                        : -1,
                    formatter: ({ assessmentDocument }) => (
                      <Typography
                        sx={{
                          overflow: "hidden"
                        }}
                        title={spNameFormatter(assessmentDocument.serviceProvider)}
                      >
                        {spNameFormatter(assessmentDocument.serviceProvider)}
                      </Typography>
                    ),
                    segmentable: true,
                    segmentBy: spNameFormatter
                  },
                  {
                    key: PatientAssessmentKeys.DEPARTMENT,
                    label: "Department",
                    formatter: ({ department }) => (
                      <Typography overflow="hidden">{department?.name || "-"}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.DATE,
                    label: tl("reports.date"),
                    sortable: true,
                    sortFunction: (a, b) =>
                      new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1,
                    formatter: ({ createdDate }) => (
                      <Typography>
                        {`${calFns.convertADtoBS(moment(createdDate)).formatted2}`}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.SYMPTOMS,
                    label: tl("reports.symptoms"),
                    sortable: true,
                    sortFunction: (a, b) =>
                      symptomsFormatter(a.assessmentDocument) >
                      symptomsFormatter(b.assessmentDocument)
                        ? 1
                        : -1,
                    formatter: ({ assessmentDocument }) => (
                      <Typography
                        title={symptomsFormatter(assessmentDocument)}
                        width={150}
                        height={40}
                        overflow="hidden"
                        paddingRight={5}
                      >
                        {symptomsFormatter(assessmentDocument)}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.ATTACHMENT_COUNT,
                    label: "Attachment count",
                    formatter: ({ attachmentCount }) => (
                      <Typography overflow="hidden">{attachmentCount || "-"}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.OPD_DURATION,
                    label: "Opd Duration",
                    sortable: true,
                    formatter: ({ assessmentDocument }) => (
                      <Typography>
                        {opdDurationFormatter(assessmentDocument.opdDuration)}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.DIAGNOSIS,
                    label: tl("reports.diagnosis"),
                    sortable: true,
                    sortFunction: (a, b) =>
                      diagnosisFormatter(a.assessmentDocument.diagnosis) >
                      diagnosisFormatter(b.assessmentDocument.diagnosis)
                        ? 1
                        : -1,
                    formatter: ({ assessmentDocument }) => (
                      <Typography
                        title={diagnosisFormatter(assessmentDocument.diagnosis)}
                        height={40}
                      >
                        {diagnosisFormatter(assessmentDocument.diagnosis)}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.MEDICATION,
                    label: tl("reports.medication"),
                    sortable: true,
                    sortFunction: (a, b) =>
                      medicationFormatter(a.assessmentDocument) >
                      medicationFormatter(b.assessmentDocument)
                        ? 1
                        : -1,
                    formatter: ({ assessmentDocument }) => (
                      <Typography
                        title={medicationFormatter(assessmentDocument)}
                        width={150}
                        overflow="hidden"
                      >
                        {medicationFormatter(assessmentDocument)}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.MEDICATION_REMARK,
                    label: tl("reports.medicationRemark"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography
                        width={220}
                        overflow="hidden"
                        title={medicationRemarkFormatter(assessmentDocument)}
                      >
                        {medicationRemarkFormatter(assessmentDocument)}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.PAST_HISTORY_OF_ALLERGY,
                    label: tl("reports.allergy"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography overflow="hidden">
                        {assessmentDocument?.pastHistoryOfAllergy}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.HOPI,
                    label: tl("reports.hopi"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography overflow="hidden">{assessmentDocument?.hopi}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.STATUS,
                    label: tl("reports.status"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography overflow="hidden">{assessmentDocument?.assessment}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.INVESTIGATION,
                    label: tl("reports.investigation"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography overflow="hidden">{assessmentDocument?.investigation}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.PRESENT_HEALTH_STATUS,
                    label: tl("reports.presentHealthStatus"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography overflow="hidden">
                        {assessmentDocument?.presentHealthStatus}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.TREATMENT_PLAN,
                    label: tl("reports.treatmentPlan"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography title={assessmentDocument?.treatmentPlan} overflow="hidden">
                        {assessmentDocument?.treatmentPlan}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.TREATMENT_GIVEN,
                    label: tl("reports.treatmentGiven"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography title={assessmentDocument?.treatmentGiven} overflow="hidden">
                        {assessmentDocument?.treatmentGiven}
                      </Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.ADVICE,
                    label: tl("reports.advice"),
                    formatter: ({ assessmentDocument }) => (
                      <Typography overflow="hidden">{assessmentDocument?.advice}</Typography>
                    )
                  },
                  {
                    key: PatientAssessmentKeys.OTHERS,
                    label: tl("reports.others"),
                    formatter: ({ others }) => <Typography overflow="hidden">{others}</Typography>
                  }
                ].filter((row) => listColumns[row.key])}
                segementSummaryRenderer={(acc) => (
                  <Typography>
                    <Box
                      component="span"
                      display="flex"
                      padding="8px 32px 4px 24px"
                      fontWeight="500"
                    >
                      {acc.segment}
                    </Box>
                  </Typography>
                )}
              >
                <EmptyView>
                  <Box textAlign="center" padding="50px">
                    No items to show.
                  </Box>
                </EmptyView>
                <ListActions>
                  {({ getProcessedData }) => (
                    <Menu>
                      <MenuItem
                        onClick={() =>
                          downloadCSV(
                            `${t("reports.patientAssessmentReport")}-${
                              calFns.convertADtoBS(filters.from).formatted
                            }-${calFns.convertADtoBS(filters.until).formatted}`,
                            getProcessedData(),
                            docColumns(),
                            (row) => docRowProcessor(row)
                          )
                        }
                      >
                        {tl("reports.excel")}
                      </MenuItem>
                      <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                      <MenuItem onClick={() => ({})}>
                        <ReportPrint
                          printData={getPrintData(filteredHeaders, getProcessedData())}
                        />
                      </MenuItem>
                    </Menu>
                  )}
                </ListActions>
              </List>
              <ListHeaderShowHideDialog
                onChange={(updatedColumns) => setListColumns(updatedColumns)}
                requiredColumns={["serviceProvider"]}
                open={open}
                onClose={() => setOpen(false)}
                columns={listColumns}
              />
            </Box>
          </Box>
        </Box>
        {showClientInfoPanel &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={selectedClientId}
              mode="edit"
              onCancel={() => {
                setClientEditMode(false);
              }}
              stayOnCurrentPage
            />
          ) : (
            <ClientInfo
              id={selectedClientId}
              handleViewClose={handleViewClose}
              stayOnCurrentPage
              setEditMode={setClientEditMode}
            />
          ))}
        {showHistoryPanel && selectedClientId && (
          <AssessmentHistoryPanel
            onClose={() => {
              setSelectedClientId(null);
              setShowHistoryPanel(false);
            }}
            clientId={selectedClientId}
          />
        )}
      </Box>
    </Can>
  );
};

const mapStateToProps = (state: RootState) => {
  const { mode } = state.userContext;
  const serviceProviderId =
    mode === "serviceProvider" ? state.userContext.userCreds.authenticableId : null;

  return {
    serviceProviderId
  };
};

export default connect(mapStateToProps)(PatientFlow);
