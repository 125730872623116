export const supportPhoneNumber = "9851200730";

export const salesPhoneNumber = "9851154810, 015320826";

export const supportEmail = "info@okhati.com.np";

export const address = "Tripura Marg, Teku, Kathmandu";

export const featuresVideos = [
  { title: "Okhati Introduction", url: "https://www.youtube.com/embed/jQzqz1gRa2Y" },
  { title: "Okhati Demo", url: "https://www.youtube.com/embed/Sx1RDnhIA4k" },
  { title: "How To Purchase Sms", url: "https://www.youtube.com/embed/_Bc9AkFxc4w " },
  {
    title: "How To Create Sales Return/Credit Note",
    url: "https://www.youtube.com/embed/ByAN87B2gKE "
  },
  {
    title: "How To Record Payment/Due Clearance",
    url: "https://www.youtube.com/embed/LugeEftudsk "
  },
  { title: "How To Edit Bill Print Margin", url: "https://www.youtube.com/embed/LugeEftudsk " },
  {
    title: "How To Upload Service Provider Signature",
    url: "https://www.youtube.com/embed/LugeEftudsk "
  },
  {
    title: "How To Enable Sms For Booking And Other Services",
    url: "https://www.youtube.com/embed/PC9QnkZC2Yk "
  }
];

export const supportVideos = [
  {
    title: "How to clear cache and hard reload?",
    url: "https://www.youtube.com/embed/Qip5ZxDa5Bo"
  },
  {
    title: "How to upload service provider signatures?",
    url: "https://www.youtube.com/embed/SPZlqbkIzUo"
  },
  {
    title: "How to edit Lab Print margin?",
    url: "https://www.youtube.com/embed/lcQZU8ZDy6s"
  },
  {
    title: "How to edit Bill Print margin?",
    url: "https://www.youtube.com/embed/_lBb6ey4ZRM"
  },
  {
    title: "How to do due bill clearance?",
    url: "https://www.youtube.com/embed/LugeEftudsk"
  },
  {
    title: "How to do sales bill return?",
    url: "https://www.youtube.com/embed/ByAN87B2gKE"
  },
  {
    title: "How to do SMS triggering?",
    url: "https://www.youtube.com/embed/PC9QnkZC2Yk"
  },
  {
    title: "How to purchase SMS?",
    url: "https://www.youtube.com/embed/_Bc9AkFxc4w"
  }
];

export const oneAppPlaystoreLink =
  "https://play.google.com/store/apps/details?id=com.okhatiapp&pcampaignid=web_share";
