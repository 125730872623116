import React from "react";
import { Box, Typography } from "@mui/material";
import {
  scaleFont,
  verifyShowTestCategory,
  getSectionStyle,
  createMarkup,
  sortCategoryKeys,
  AdvancedLabRangeHandler,
  advancedLabRangeIndicator,
  showCategory,
  showSubRow,
  addDefaultFormData,
  LabHeadersFlexBasisLargeFont,
  readingPresent,
  isRadiologyOrCytology
} from "../../LabPrintFunctions";
import styles from "../../../Lab.module.css";
import { LabTest, LabTestRecordResultData } from "../../../../../interfaces/Lab";
import { EditableTableView } from "../../../../../components/EditableTable/EditableTable";
import hasOwnProperty from "../../../../../helpers/object";
import { removeCommasFromNumber } from "../../../../../helpers/number";
import { TestNameWithSampleInfo } from "../DefaultTemplate/DefaultTemplateCore";

const EXCLUDE_GROUP_CATEGORY = ["Special", "undefined"]; // add group category here to hide

const CustomEl = ({ data }) => (
  <div dangerouslySetInnerHTML={createMarkup(data)} className={styles.additionalData} />
);

interface SubRowProps {
  pl: number;
  lt: LabTest;
  children?: React.ReactNode;
  hasSubTests?: boolean;
  pushRight?: boolean;
  idx: number;
  gender: "1" | "2";
  dob: string;
  showMethods: boolean;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  showRangeFlag: boolean;
}

export const SubRow = ({
  pl,
  lt,
  children = <></>,
  hasSubTests = false,
  pushRight = false,
  idx,
  gender,
  showMethods,
  dob,
  showRanges,
  showUnit,
  showReading,
  showRangeFlag
}: SubRowProps): JSX.Element => {
  const commonStyles = { fontSize: scaleFont("0.33cm", 1.105), wordBreak: "break-all" };

  const rangeSymbol = advancedLabRangeIndicator(
    Number(removeCommasFromNumber(lt.formData.reading)),
    lt.ranges,
    dob,
    gender
  );

  return (
    <>
      {showSubRow(hasSubTests, lt) && (
        <Box
          p="0.02cm"
          pl={pushRight ? "0.4cm" : "0.2cm"}
          borderLeft="1px solid black"
          borderRight="1px solid black"
          borderTop={!idx && 0}
          borderBottom="1px solid black"
          style={{
            pageBreakInside: "avoid"
          }}
        >
          <Box display="flex">
            <Box
              flexBasis={pushRight ? "5.6cm" : "5.8cm"}
              flexGrow="0.4"
              flexShrink="0.4"
              style={{ paddingLeft: `${pl}cm` }}
            >
              <Typography
                style={{
                  fontSize: scaleFont("0.33cm", 0.95),
                  paddingRight: "0.6cm",
                  ...(hasSubTests && { fontWeight: "bold" })
                }}
              >
                {lt.name}
              </Typography>
            </Box>
            {showReading && (
              <Typography
                style={{
                  ...commonStyles,
                  fontWeight: rangeSymbol ? 900 : 500,
                  marginRight: "0.5cm"
                }}
                flexBasis="4cm"
                flexGrow="0.15"
                flexShrink="0.15"
              >
                {lt?.formData.reading || null}
              </Typography>
            )}
            {showRangeFlag && (
              <Typography style={commonStyles} flexBasis="1.5cm" flexGrow="0.15" flexShrink="0.15">
                {rangeSymbol.highSymbol || rangeSymbol.lowSymbol || null}
              </Typography>
            )}
            {showUnit && (
              <Typography style={commonStyles} flexBasis="1.8cm" flexGrow="0.15" flexShrink="0.15">
                {!(lt.subTests?.length > 0) && hasOwnProperty(lt, "unit") && lt.unit}
              </Typography>
            )}
            {showRanges && (
              <Box flexBasis="3.7cm" flexGrow="0.15" flexShrink="0.15">
                <AdvancedLabRangeHandler range={lt.ranges} dob={dob} gender={Number(gender)} />
              </Box>
            )}
            {showMethods && (
              <Typography style={commonStyles} flexBasis="3cm" flexGrow="0.15" flexShrink="0.15">
                {lt.methods}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {children}
      {lt.subTests?.map((sT, i) => (
        <SubRow
          pl={pl + 0.5}
          lt={addDefaultFormData(sT)}
          key={sT.id}
          idx={i}
          dob={dob}
          gender={gender}
          showMethods={showMethods}
          showRanges={showRanges}
          showUnit={showUnit}
          showReading={showReading}
          showRangeFlag={showRangeFlag}
          hasSubTests={Boolean(sT.subTests?.length)}
        />
      ))}
    </>
  );
};

SubRow.defaultProps = {
  hasSubTests: false,
  pushRight: false,
  children: <></>
};

interface RowProps {
  item: LabTestRecordResultData;
  pushRight: boolean;
  gender: "1" | "2";
  showAdditionalLabData: boolean;
  showMethods: boolean;
  dob: string;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  showRangeFlag: boolean;
  showSampleCollectedBy: boolean;
}

export const Row = ({
  item,
  pushRight,
  gender,
  showAdditionalLabData,
  showMethods,
  dob,
  showRanges,
  showUnit,
  showReading,
  showRangeFlag,
  showSampleCollectedBy
}: RowProps): JSX.Element => {
  const showTestCategory = verifyShowTestCategory(item);
  return (
    <div>
      <Box
        sx={{
          paddingLeft: "0.4cm",
          paddingTop: "0.1cm",
          width: "100%",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <TestNameWithSampleInfo
          test={{
            name: showTestCategory ? item.name : "",
            style: {
              fontSize: scaleFont("0.33cm", 0.95)
            }
          }}
          sampleInfo={{
            sampleType: item.sampleInfo?.sampleType
          }}
          collectedBy={{ name: showSampleCollectedBy ? item.collectedBy?.name : "" }}
        />
      </Box>
      {item.labTests?.map((lt, i) => (
        <SubRow
          pl={showTestCategory ? 0.5 : 0}
          lt={addDefaultFormData(lt)}
          hasSubTests={Boolean(lt.subTests?.length)}
          pushRight={pushRight}
          key={lt.id}
          idx={i}
          dob={dob}
          gender={gender}
          showMethods={showMethods}
          showRanges={showRanges}
          showUnit={showUnit}
          showReading={showReading}
          showRangeFlag={showRangeFlag}
        />
      ))}
      {showAdditionalLabData && item?.additionalTestData && (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <CustomEl data={item?.additionalTestData} />
        </div>
      )}
      <div>
        {item?.interpretationTemplate && (
          <EditableTableView tableState={item?.interpretationTemplate} />
        )}
      </div>
    </div>
  );
};

export const DataHeaders = ({
  headers = ["Test"],
  headerColor,
  showMethods,
  showRanges,
  showUnit,
  showReading,
  type,
  readingsPresent,
  showRangeFlag
}: {
  headers?: string[];
  headerColor: string;
  showMethods: boolean;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  type: string;
  readingsPresent: boolean;
  showRangeFlag: boolean;
}): JSX.Element => {
  function handleHeaders(h = headers) {
    const headings = [...h];
    if (showReading) {
      headings.push("Result");
    }
    if (showRangeFlag) {
      headings.push("Flag");
    }
    if (showUnit) {
      headings.push("Unit");
    }
    if (showRanges) {
      headings.push("Range");
    }
    if (showMethods) {
      headings.push("Methods");
    }
    if (isRadiologyOrCytology(type)) {
      headings.splice(2, headings.length);
    }
    return headings;
  }

  return (
    <>
      {readingsPresent && (
        <Box display="flex" p="0.1cm 0.3cm" style={getSectionStyle(headerColor).rawHeaderStyle}>
          {handleHeaders().map((colHeader, i) => (
            <Typography
              key={colHeader}
              style={{
                flexGrow: i === 0 ? 0.4 : 0.15,
                flexShrink: i === 0 ? 0.4 : 0.15,
                flexBasis: LabHeadersFlexBasisLargeFont[colHeader] || "6cm",
                fontSize: scaleFont("0.27cm", 1.3),
                textTransform: "uppercase",
                fontWeight: 600
              }}
            >
              {colHeader}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};

DataHeaders.defaultProps = {
  headers: ["Test"]
};

interface LabPrintCoreDataProps {
  labData: Record<string, any>;
  showAdditionalLabData: boolean;
  showMethods: boolean;
  categorySortOrder: Record<string, any>;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  showRangeFlag: boolean;
  showSampleCollectedBy: boolean;
}

export const CoreData = ({
  labData,
  showAdditionalLabData,
  showMethods,
  categorySortOrder,
  showRanges,
  showUnit,
  showReading,
  showRangeFlag,
  showSampleCollectedBy
}: LabPrintCoreDataProps): JSX.Element => {
  const { data } = labData.results;
  const { gender, dob } = labData.client;
  const showRowColumn = !isRadiologyOrCytology(labData.type);

  return (
    <div>
      {sortCategoryKeys(
        Object.keys(data),
        categorySortOrder?.map((item) => item.category) || []
      ).map(
        (item) =>
          showCategory(data[item]) && (
            <Box key={item}>
              {!EXCLUDE_GROUP_CATEGORY.includes(item) && (
                <Typography
                  key={item}
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    paddingLeft: "0.2cm",
                    paddingTop: "0.08cm",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    textDecoration: "underline",
                    fontSize: scaleFont("0.33cm", 1.192)
                  }}
                >
                  {item}
                </Typography>
              )}
              <div>
                {data[item].map((test) => (
                  <Row
                    key={test.name}
                    item={test}
                    pushRight={!EXCLUDE_GROUP_CATEGORY.includes(item)}
                    gender={gender}
                    showAdditionalLabData={showAdditionalLabData}
                    showMethods={showRowColumn && showMethods}
                    dob={dob}
                    showRanges={showRowColumn && showRanges}
                    showUnit={showRowColumn && showUnit}
                    showReading={showReading}
                    showRangeFlag={showRangeFlag}
                    showSampleCollectedBy={showSampleCollectedBy}
                  />
                ))}
              </div>
            </Box>
          )
      )}
    </div>
  );
};

export const DataComment = ({ comment }: { comment: string }): JSX.Element => (
  <Box mt="16px" display="flex">
    <Typography
      style={{ fontSize: scaleFont("0.33cm", 1.105), whiteSpace: "pre-wrap", width: "100%" }}
    >
      <CustomEl data={comment} />
    </Typography>
  </Box>
);

const LabPrintCoreData = ({
  labData,
  headerColor,
  showAdditionalLabData,
  showMethods,
  categorySortOrder,
  showRanges,
  showUnit,
  showReading,
  showRangeFlag,
  showSampleCollectedBy
}: LabPrintCoreDataProps & { headerColor: string }): JSX.Element => {
  const readingContains = readingPresent(labData);
  return (
    <div>
      <div>
        <DataHeaders
          headerColor={headerColor}
          showMethods={showMethods}
          type={labData.type}
          showRanges={showRanges}
          showUnit={showUnit}
          showReading={showReading}
          readingsPresent={readingContains}
          showRangeFlag={showRangeFlag}
        />
        <CoreData
          labData={labData}
          showAdditionalLabData={showAdditionalLabData}
          showMethods={showMethods}
          categorySortOrder={categorySortOrder}
          showRanges={showRanges}
          showUnit={showUnit}
          showReading={showReading}
          showRangeFlag={showRangeFlag}
          showSampleCollectedBy={showSampleCollectedBy}
        />
      </div>

      <Box mt="8px">
        {labData.results.showComment && labData.results.comment && (
          <DataComment comment={labData.results.comment} />
        )}
      </Box>
    </div>
  );
};

export default LabPrintCoreData;
