import { Box, Typography } from "@mui/material";
import * as React from "react";
import { notificationAdd } from "../../actions/notification";
import * as reportsApi from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadCSV } from "../../helpers/files";
import useCan from "../../hooks/useCan";
import useMobileScreen from "../../hooks/useMobileScreen";
import { HMISReportLabTest, HMISReportRow } from "../../interfaces/ReportInterface";
import { useAppDispatch } from "../../store/hooks";
import Filters from "./Filters";
import "./Reports.scss";

const docColumns = () => [
  "category",
  "groupName",
  "testName",
  "lab.totalTests",
  "lab.positiveCount"
];

interface ReportRow extends HMISReportLabTest {
  groupName: string;
  category: string;
}

const splitByLab = (data: HMISReportRow) =>
  data.groups.reduce((acc, cur) => {
    const labs: Array<ReportRow> = [];
    cur.labTests.forEach((test) => {
      labs.push({ ...test, groupName: cur.name, category: data.category });
    });
    return acc.concat(...labs);
  }, [] as Array<ReportRow>);

const formatData = (data: Array<HMISReportRow>) =>
  data.reduce((acc, cur) => [...acc, ...splitByLab(cur)], [] as Array<ReportRow>);

function addId<T>(data: T, idx: number): T & { id: number } {
  return { ...data, id: idx };
}

const LabReport = (): JSX.Element => {
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const isSuperAdmin = useCan("deny", { superAdminPass: true, supportAccountAdminPass: true });
  const [filters, setFilters] = React.useState({
    from: new Date(calFns.startOfDay(new Date()).toISOString()),
    until: new Date(),
    ...(isSuperAdmin && { clinicSelect: null })
  });

  const [reportData, setReportData] = React.useState<Array<ReportRow & { id: number }>>([]);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        setIsRequestLoading(true);
        if (isSuperAdmin && !filters.clinicSelect) {
          return;
        }
        const data = await reportsApi.hmisLabReport(filters);
        if (data) setReportData(formatData(data).map(addId));
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: e?.data?.message || "Sorry !, something went wrong.",
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [filters, dispatch, isSuperAdmin]);

  const applyFilters = (f) => {
    setFilters(f);
  };

  const isMobileScreen = useMobileScreen();
  return (
    <Box marginTop="32px">
      <Box margin="0px 32px">
        <Box marginTop="32px">
          <Filters
            filters={filters}
            onSetFilters={(f) => applyFilters(f)}
            isRequestLoading={isRequestLoading}
          />
        </Box>
      </Box>
      <Box
        width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}
        style={{ overflowX: isMobileScreen ? "scroll" : "auto" }}
        mx="auto"
      >
        <Box className="hmislablist" marginTop="32px" width={isMobileScreen ? "960px" : "auto"}>
          <List
            automation="hmislablist"
            data={reportData}
            rowHeight={50}
            defaultSortColumn="category"
            defaultSortOrder={-1}
            adjustHeightToContents
            columns={[
              {
                key: "name",
                label: tl("others.HMIS.LabTest"),
                sortable: true,
                formatter: (row) => row.name
              },
              {
                key: "group",
                label: tl("others.HMIS.GroupName"),
                sortable: true,
                formatter: (row) => row.groupName
              },
              {
                key: "category",
                label: tl("others.HMIS.Category"),
                sortable: true,
                formatter: (row) => (
                  <Typography style={{ fontSize: "0.75rem" }}>{row.category}</Typography>
                ),
                segmentable: true,
                segmentBy(row) {
                  return row.category;
                }
              },
              {
                key: "total",
                label: tl("others.HMIS.TotalTest"),
                sortable: true,
                formatter: (row) => row.total.toString()
              },
              {
                key: "positiveNumber",
                label: tl("others.HMIS.Positive"),
                sortable: true,
                formatter: (row) => row.positiveNumber.toString()
              }
            ]}
            segementSummaryRenderer={(acc) => (
              <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                <Box display="flex" flexGrow={1} justifyContent="space-between">
                  <Box
                    component="span"
                    flexGrow={1}
                    display="flex"
                    padding="8px 32px 4px 20px"
                    fontWeight="500"
                    alignItems="center"
                  >
                    {acc.segment || "-"}
                  </Box>
                  <Box p={2} pr={4} fontWeight={600}>
                    {acc.rows.length}
                  </Box>
                </Box>
              </Box>
            )}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
            <ListActions>
              {({ getProcessedData }) => (
                <Menu>
                  <MenuItem
                    onClick={() =>
                      downloadCSV(
                        t("reports.labReport", "en"),
                        getProcessedData(),
                        docColumns(),
                        (row) => [
                          row.category,
                          row.groupName,
                          row.name,
                          row.total || 0,
                          row.positiveNumber || 0
                        ]
                      )
                    }
                  >
                    {tl("reports.excel")}
                  </MenuItem>
                </Menu>
              )}
            </ListActions>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default LabReport;
