import * as React from "react";
import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import { useDispatch } from "react-redux";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import * as reportsApi from "../../api/reports";
import { downloadExcel } from "../../helpers/files";
import { tl, t } from "../../components/translate";
import "./Reports.scss";
import Filters from "./Filters";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import Can from "../Policy/Can";
import { ClientLedgerReport as ClientLedgerReportInterface } from "../../interfaces/ClientLedgerReport";
import BillShow from "../Billing/BillShow/BillShow";
import { notificationAdd } from "../../actions/notification";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import {
  clientLedgerDefaultCols,
  getDefaultKeyValuesColumns
} from "../../components/ListHeaderShowHideDialog/helpers";
import { roundTwoDecimalPoint } from "../../helpers/number";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const docColumns = () => [
  t("reports.transactionDate"),
  t("reports.clientName"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("reports.voucherType"),
  t("reports.documentNumber"),
  t("reports.paymentMethod"),
  t("reports.debit"),
  t("reports.credit"),
  t("reports.runningBalance")
];

const docRowProcessor = ({
  clientFullName,
  firstName,
  lastName,
  debit,
  credit,
  documentNumber,
  voucherType,
  transactionDate,
  paymentMethod,
  balance,
  // eslint-disable-next-line camelcase
  __meta__row_type
}: // eslint-disable-next-line camelcase
Partial<ClientLedgerReportInterface & { __meta__row_type: string }>) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    calFns.bsFullDate(transactionDate) || "-",
    clientFullName || "-",
    firstName || "-",
    lastName || "-",
    voucherType || "-",
    documentNumber || "-",
    paymentMethod || "-",
    debit || "-",
    credit || "-",
    balance || "-"
  ];
};

const ClientLedgerReport = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);

  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    clientIds: []
  });

  const [reportData, setReportData] = React.useState<Array<ClientLedgerReportInterface>>([]);

  React.useEffect(() => {
    (async () => {
      try {
        setIsRequestLoading(true);
        const data = await reportsApi.clientLedgerReport(filters);
        setReportData(data);
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to fetch report!",
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [filters]);

  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const [selectedBill, setSelectedBill] = React.useState(null);
  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const handleViewClose = () => {
    setSelectedClientId(null);
    setClientInfoPanel(false);
  };
  const [clientEditMode, setClientEditMode] = React.useState(false);

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(clientLedgerDefaultCols)
  );
  const [open, setOpen] = React.useState(false);
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:*">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1100px" : "auto"}>
          <Box margin="0px 32px">
            <Filters
              showTillDate
              filters={filters}
              onSetFilters={(f) => setFilters(f)}
              isRequestLoading={isRequestLoading}
            />
          </Box>

          <Box marginTop="32px" height="calc(100vh - 225px)" className="clientLedgerReport">
            <List<ClientLedgerReportInterface>
              automation="receiptReportList"
              data={reportData}
              rowHeight={50}
              defaultSortColumn="clientFullName"
              defaultSortOrder={1}
              activeRow={0}
              adjustHeightToContents
              columns={[
                {
                  key: "transactionDate",
                  label: "Transaction Date",
                  formatter: (row) => (
                    <Typography>{calFns.bsFullDate(row.transactionDate)}</Typography>
                  ),
                  sortable: true
                },
                {
                  key: "clientFullName",
                  label: "Client Name",
                  formatter: (row) => (
                    <Typography
                      sx={{ textDecoration: row.isClientActive ? "underline" : "none" }}
                      onClick={() => {
                        if (row.isClientActive) {
                          setSelectedClientId(row.clientId);
                          setClientInfoPanel(true);
                        }
                      }}
                    >
                      {row.clientFullName.toUpperCase()} {!row.isClientActive && "(Deleted)"}
                    </Typography>
                  ),
                  segmentable: true,
                  segmentBy: (row) => row.clientFullName,
                  sortable: true
                },
                {
                  key: "voucherType",
                  label: tl("reports.voucherType"),
                  formatter: (row) => <Typography>{startCase(row.voucherType)}</Typography>,
                  sortable: true
                },
                {
                  key: "documentNumber",
                  label: "Document Number",
                  formatter: (row) => (
                    <Typography
                      onClick={() => {
                        if (row.billId) {
                          setSelectedBill(row.billId);
                        }
                      }}
                      sx={row.billId ? { textDecoration: "underline", color: "primary.main" } : {}}
                    >
                      {row.documentNumber} {row.billId && "(Bill)"}
                    </Typography>
                  ),
                  sortable: true
                },
                {
                  key: "paymentMethod",
                  label: "Payment Method",
                  formatter: (row) => <Typography>{row.paymentMethod || "-"}</Typography>,
                  sortable: true,
                  sortFunction: (a, b) =>
                    new Date(a.transactionDate) > new Date(b.transactionDate) ? 1 : -1
                },
                {
                  key: "debit",
                  label: "Debit",
                  formatter: (row) => (
                    <Typography>{roundTwoDecimalPoint(row.debit || 0)}</Typography>
                  )
                },
                {
                  key: "credit",
                  label: "Credit",
                  formatter: (row) => (
                    <Typography>{roundTwoDecimalPoint(row.credit || 0)}</Typography>
                  )
                },
                {
                  key: "balance",
                  label: "Running Balance",
                  formatter: (row) => <Typography>{row.balance || 0}</Typography>
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(acc) => (
                <Box
                  bgcolor="#e6e6e6"
                  display="flex"
                  px="20px"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <Typography>{acc.segment}</Typography>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            t("reports.receiptReport", "en"),
                            formatHeaderForExcel(docColumns()),
                            formatDataForExcel(getProcessedData(), docRowProcessor)
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              requiredColumns={["clientFullName"]}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
        </Box>
        {showClientInfoPanel &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={selectedClientId}
              mode="edit"
              onCancel={() => {
                setClientEditMode(false);
              }}
              stayOnCurrentPage
            />
          ) : (
            <ClientInfo
              id={selectedClientId}
              handleViewClose={handleViewClose}
              stayOnCurrentPage
              setEditMode={setClientEditMode}
            />
          ))}

        {selectedBill && (
          <BillShow
            billId={selectedBill}
            handleViewClose={() => setSelectedBill(null)}
            hideActions
          />
        )}
      </Box>
    </Can>
  );
};

export default ClientLedgerReport;
