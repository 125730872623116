import { Box } from "@mui/material";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import Filters from "./Filters";
import { IThunkDispatch } from "../../store";
import PageControl from "../../components/PageControl";
import * as serviceActions from "../../actions/services";
import useMobileScreen from "../../hooks/useMobileScreen";
import { Client } from "../../interfaces/ClientInterface";
import { notificationAdd } from "../../actions/notification";
import OpdRequestList from "../Assessment/OpdRequest/OpdRequestList";
import { getOpdRequests, RequestForm } from "../../api/requestForms";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import OpdRequestInfo from "../Assessment/OpdRequest/OpdRequestInfo";

const LabReferralReport = ({ services, serviceProviderId, actions }): JSX.Element => {
  const pageSize = 20;
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [selectedClient, setSelectedClient] = useState<Partial<Client> | null>(null);
  const [selectedRow, setSelectedRow] = useState<Partial<RequestForm> | null>(null);
  const [filters, setFilters] = useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    clientIds: [],
    serviceProviderIds: serviceProviderId ? [serviceProviderId] : []
  });
  // eslint-disable-next-line max-len
  const [opdRequestData, setOpdRequestData] = useState<{ results: RequestForm[]; total: number }>({
    results: [],
    total: 0
  });

  const isMobileScreen = useMobileScreen();
  const { isRnWebView } = useIsReactNativeWebView();

  useEffect(() => {
    (async () => {
      try {
        setIsRequestLoading(true);
        // eslint-disable-next-line max-len
        const response = await getOpdRequests({ page, pageSize, ...filters });
        setOpdRequestData(response);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get request forms",
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [page, dispatch, selectedClient?.id, filters]);

  React.useEffect(() => {
    if (!services || !services.length) {
      actions.loadServices();
    }
  }, [actions, services]);

  return (
    <Box overflow="auto hidden">
      <Box minWidth={isRnWebView ? "1000px" : "auto"}>
        <Box mx="32px">
          <Filters
            filters={filters}
            onSetFilters={(newfilters) => {
              setFilters(newfilters);
              setPage(0);
            }}
            isRequestLoading={isRequestLoading}
          />
        </Box>
        <Box width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}>
          <Box
            marginTop="15px"
            width={isMobileScreen ? "960px" : "auto"}
            height="calc(100vh - 260px)"
            className="smsReportList"
          >
            <OpdRequestList
              page={page}
              setPage={setPage}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              opdRequestData={opdRequestData}
              isListForReport
            />

            {selectedRow && (
              <OpdRequestInfo
                onClose={() => setSelectedRow(null)}
                selectedRow={selectedRow}
                opdRequestData={opdRequestData}
                setOpdRequestData={setOpdRequestData}
                isInfoRenderedInReport
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box className="labNavigatePage">
        <PageControl
          page={page}
          pageSize={pageSize}
          onSetPage={(v) => {
            setPage(v);
          }}
          maximumDataCount={opdRequestData.total}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { mode } = state.userContext;
  const serviceProviderId =
    mode === "serviceProvider" ? state.userContext.userCreds.authenticableId : null;

  return {
    services: state.services.collection,
    serviceProviderId
  };
};

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
  actions: {
    loadServices: () => dispatch(serviceActions.getServices()),
    navigateTo: (url) => dispatch(push(url))
  }
});

export default connect(mapStateToProps, matchDispatchToProps)(LabReferralReport);
