import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  CircularProgress
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/CheckCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { SimpleTreeView } from "@mui/x-tree-view";
import { tl } from "../../../components/translate";
import * as NotificationActions from "../../../actions/notification";
import { getChildren } from "../../../api/assessment";
import { commonErrorMessage } from "../../../helpers/messages";
import { useAppDispatch } from "../../../store/hooks";
import { ICD } from "../../../interfaces/AssessmentInterfaces";
import { IcdItemHashMap } from "./DiagnosisWithTreeView";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  onChange: (value: Partial<ICD>) => void;
  icdItemsHash: IcdItemHashMap;
  onIcdItemChange: (items: ICD[]) => void;
}

const DiagnosisTreeViewDialog = ({
  isOpen,
  onClose,
  onChange,
  icdItemsHash,
  onIcdItemChange
}: Props): JSX.Element => {
  const [checkedLeafId, setCheckedLeafId] = React.useState<number>();

  const dispatch = useAppDispatch();

  const renderNodes = (parentId = null): JSX.Element => (
    <>
      {icdItemsHash[parentId] &&
        (Object.values(icdItemsHash[parentId]) as ICD[]).map((node) =>
          !node.isLeaf ? (
            <TreeItem
              key={node.id}
              itemId={node.id.toString()}
              nodeId={node.id}
              label={
                <Typography
                  component="span"
                  onClick={() => {
                    setCheckedLeafId(node.id);
                  }}
                >
                  <b>{node.code}</b>:{node.title}
                </Typography>
              }
            >
              {icdItemsHash[node.id] ? renderNodes(node.id) : <CircularProgress size={16} />}
            </TreeItem>
          ) : (
            <TreeItem
              itemId={node.id.toString()}
              key={node.id}
              nodeId={node.id}
              label={
                <Box
                  onClick={() => {
                    setCheckedLeafId(node.id);
                    onChange({
                      title: `${node.code} ${node.title}`,
                      code: node.code
                    });
                    onClose();
                  }}
                  display="flex"
                >
                  {checkedLeafId === node.id && <CheckIcon />}
                  <Typography component="span">
                    <b>{node.code} </b>:{node.title}
                  </Typography>
                </Box>
              }
            />
          )
        )}
    </>
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight="600">{tl("assessment.diseaseClassificationTree")}</Typography>
          <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
        </Box>
      </DialogTitle>

      <DialogContent>
        <div>
          <SimpleTreeView
            slots={{
              // eslint-disable-next-line react/jsx-props-no-spreading
              expandIcon: (props) => <ExpandMoreIcon {...props} />,
              // eslint-disable-next-line react/jsx-props-no-spreading
              collapseIcon: (props) => <ChevronRightIcon {...props} />
            }}
            onExpandedItemsChange={async (_, itemId) => {
              if (!icdItemsHash[itemId[0]]) {
                try {
                  const response = await getChildren(itemId[0]);
                  onIcdItemChange(response);
                } catch (error) {
                  dispatch(
                    NotificationActions.notificationAdd({
                      id: new Date().getUTCMilliseconds(),
                      variant: "error",
                      message: error?.data?.message || commonErrorMessage,
                      autoTimeout: true
                    })
                  );
                }
              }
            }}
          >
            {renderNodes()}
          </SimpleTreeView>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DiagnosisTreeViewDialog;
