import { Box, Link, Typography } from "@mui/material";
import { push } from "connected-react-router";
import capitalize from "lodash/capitalize";
import moment from "moment";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { notificationAdd } from "../../actions/notification";
import * as reportsApi from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadExcel, downloadXML } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import styles from "./Reports.module.css";

import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import useIpdBeds from "../../hooks/useIpdBeds";
import { BillStatus, DocumentTypes, VisitType } from "../../interfaces/BillInterfaces";
import BillShow from "../Billing/BillShow/BillShow";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import Can from "../Policy/Can";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import Filters from "./Filters";
import "./Reports.scss";
import {
  getDefaultKeyValuesColumns,
  salesDefaultCols,
  salesHiddenCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import { isSelfDataAccessEmployee } from "../NavigationBar/SideBar";
import { RootState } from "../../store";
import { getCustomerNumber } from "../Client/ClientList";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

interface SalesReportProps {
  actions: {
    navigateTo: (url) => void;
    addNotification: (statusCode) => void;
  };
}

interface DocRowProcessorInterface {
  billInfo: { billId: number; billNumber: string; related: null | string; type: string };
  billingDate: Date;
  clientFirstName: string;
  clientLastName: string;
  enteredBy: string;
  clientName: string;
  customerNumber: string;
  discount: number;
  grossAmount: number;
  paymentMethod: string;
  referrers: string;
  remarks: string;
  services: Array<string>;
  status: string;
  taxAmount: number;
  taxableAmount: number;
  paidAmount: number;
  dueAmount: number;
  totalAmount: number;
  admissionDate: string;
  dischargeDate: string;
  bedName: string;
  registrationNo: string;
  // eslint-disable-next-line camelcase
  __meta__row_type?: string;
}

const or = (val) => val || "";

const docColumns = () => [
  t("reports.customerNumber"),
  t("reports.client"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("registrationNo"),
  t("reports.billedBy"),
  t("reports.services"),
  t("reports.billNumber"),
  t("reports.billingDateBS"),
  t("reports.billingDateAD"),
  t("reports.grossAmount"),
  t("reports.discount"),
  t("reports.taxableAmount"),
  t("reports.taxAmount"),
  t("reports.paidAmount"),
  t("reports.dueAmount"),
  t("reports.totalAmount"),
  t("reports.remarks"),
  t("reports.payment.method"),
  t("reports.creditNoteFor"),
  t("reports.billStatus"),
  t("reports.referrer"),
  t("reports.extraReferrer"),
  t("reports.admissionDate"),
  t("reports.dischargeDate"),
  t("reports.bed")
];

const docRowProcessor = (
  {
    customerNumber,
    clientFirstName,
    clientLastName,
    registrationNo,
    clientName,
    billInfo,
    billingDate,
    grossAmount,
    discount,
    taxableAmount,
    taxAmount,
    paidAmount,
    totalAmount,
    services,
    remarks,
    paymentMethod,
    status,
    referrers,
    extraReferrer,
    enteredBy,
    admissionDate,
    dischargeDate,
    bedName,
    // eslint-disable-next-line camelcase
    __meta__row_type
  } = {} as DocRowProcessorInterface
) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return "";
  const dueAmount = totalAmount - paidAmount || 0;
  return [
    or(getCustomerNumber(customerNumber)),
    or(clientName),
    or(clientFirstName),
    or(clientLastName),
    registrationNo,
    or(enteredBy),
    services,
    billInfo.billNumber,
    calFns.bsShortDate(billingDate),
    calFns.adShortDate(billingDate),
    grossAmount,
    discount,
    taxableAmount,
    taxAmount,
    paidAmount,
    dueAmount,
    totalAmount,
    remarks,
    paymentMethod,
    or(billInfo.type === DocumentTypes.CREDITNOTE && billInfo.related?.billNumber),
    status,
    referrers,
    extraReferrer,
    admissionDate ? convertADtoBS(new Date(admissionDate)).formatted4 : "",
    dischargeDate ? convertADtoBS(new Date(dischargeDate)).formatted4 : "",
    bedName
  ];
};

const selectData = (item, beds) => ({
  clientName: `${capitalize(item.clientFirstName)} ${capitalize(item.clientLastName)}`,
  clientId: item.clientId,
  customerNumber: item.customerNumber,
  clientFirstName: item.clientFirstName,
  clientLastName: item.clientLastName,
  active: item.active,
  billInfo: {
    billNumber: item.billNumber,
    billId: item.billId,
    related: item.related,
    type: item.type
  },
  grossAmount: item.grossAmount,
  billingDate: new Date(item.issueDate),
  taxableAmount: item.taxableAmount,
  discount: item.discount,
  taxAmount: item.taxAmount,
  totalAmount: item.totalAmount,
  paymentMethod: item.paymentMethod,
  services: item.services,
  remarks: item.remarks,
  status: item.status,
  referrers: item.referrers,
  extraReferrer: item.extraReferrer,
  paidAmount: item.paidAmount,
  enteredBy: item.enteredBy,
  createdAt: item.createdAt,
  admissionDate: item.admissionDate,
  dischargeDate: item.dischargeDate,
  registrationNo: item.registrationNo,
  bedId: item.bedId,
  bedName: beds.find((bed) => bed.id === item.bedId)?.name || ""
});

const getFilteredCancelledBills = (data, filters) =>
  data.filter((item) => {
    if (filters.showCancelledBill) {
      return true;
    }
    return item.status !== BillStatus.cancelled;
  });

const processData = (data, beds) => data.map((item) => selectData(item, beds));

const SalesReport = ({ actions }: SalesReportProps) => {
  const dispatch = useDispatch();
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const isCounterBillingEnabled =
    useSelector(
      (state: RootState) =>
        state.userContext.resourceCentre?.settings?.billingSettings?.enableCounterBilling
    ) || false;
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    clientIds: [],
    billType: null,
    includeDrafts: false,
    referrersName: [],
    employeeIds: [],
    showCancelledBill: false,
    visitType: VisitType.OPD,
    paymentMethods: [],
    ...(isCounterBillingEnabled ? { counter: "" } : {})
  });
  const beds = useIpdBeds();
  const [reportData, setReportData] = React.useState([]);
  const total = React.useMemo(
    () =>
      reportData.reduce(
        (acc, cur) => ({
          amount: acc.amount + cur.totalAmount,
          paid: acc.paid + cur.paidAmount
        }),
        { paid: 0, amount: 0 }
      ),
    [reportData]
  );
  const totalDueAmount = total.amount - total.paid;

  const userContext = useSelector((state: RootState) => state.userContext);
  const permissionGroup =
    userContext && userContext.userCreds.userGroups && userContext.userCreds.userGroups[0];

  const hideEmployeeSelect = isSelfDataAccessEmployee(permissionGroup);

  React.useEffect(() => {
    setIsRequestLoading(true);
    reportsApi
      .salesReport(filters)
      .then((data) => {
        setReportData(getFilteredCancelledBills(processData(data, beds), filters));
      })
      .catch((err) => {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: err?.data?.message || "Failed to fetch sales report.",
            autoTimeout: true
          })
        );
      })
      .finally(() => setIsRequestLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const [selectedBill, setSelectedBill] = React.useState(null);
  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const clientInfoHandle = () => {
    setSelectedClientId(null);
    setClientInfoPanel(false);
  };

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(salesDefaultCols, salesHiddenCols)
  );
  const [open, setOpen] = React.useState(false);

  const [clientEditMode, setClientEditMode] = React.useState(false);
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:salesReport">
      <Box mt={4} overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1300px" : "auto"}>
          <Box margin="0px 32px">
            <Filters
              filters={filters}
              onSetFilters={(newFilters) => applyFilters(newFilters)}
              hideEmployeeSelect={hideEmployeeSelect}
              isRequestLoading={isRequestLoading}
            />
          </Box>
          <Box
            className={["salesReportList", styles.salesSummaryListStyles].join(" ")}
            marginTop="32px"
            marginBottom="40px"
          >
            <List
              automation="salesReportList"
              data={reportData}
              rowHeight={50}
              defaultSortColumn="billingDate"
              defaultSortOrder={-1}
              activeRow={1}
              adjustHeightToContents
              columns={[
                {
                  key: "clientName",
                  label: tl("reports.client"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.clientName).toLowerCase() > or(b.clientName).toLowerCase() ? 1 : -1,
                  formatter: ({
                    clientFirstName,
                    clientLastName,
                    clientId,
                    customerNumber,
                    active
                  }) => (
                    <Typography
                      component="div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start"
                      }}
                    >
                      <Box
                        style={{
                          textDecoration: `${active ? "underline" : ""}`,
                          cursor: "pointer",
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                        onClick={() => {
                          if (active) {
                            setSelectedClientId(clientId);
                            setClientInfoPanel(true);
                          }
                        }}
                      >
                        {`${clientFirstName} ${clientLastName}`.toUpperCase()}
                        {active ? "" : "(deleted)"}
                      </Box>
                      <Box fontSize="small" color="gray">
                        {getCustomerNumber(customerNumber)}
                      </Box>
                    </Typography>
                  )
                },
                {
                  key: "billInfo",
                  label: tl("reports.billNumber"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.billInfo.billNumber).toLowerCase() >
                    or(b.billInfo.billNumber).toLowerCase()
                      ? 1
                      : -1,
                  formatter: ({ billInfo }) => (
                    <Box flex={1} flexDirection="column" alignItems="center" component={Typography}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link onClick={() => setSelectedBill(billInfo.billId)}>
                        {billInfo.billNumber}
                      </Link>
                      {billInfo.type === DocumentTypes.CREDITNOTE && billInfo.related && (
                        <Box fontSize="small" color="gray">
                          CN {billInfo.related.billNumber}
                        </Box>
                      )}
                    </Box>
                  )
                },
                {
                  key: "billingDate",
                  label: tl("reports.billingDate"),
                  formatter: ({ billingDate, createdAt }) => (
                    <Box>
                      <Typography>{calFns.bsFullDate(billingDate)}</Typography>
                      {createdAt && !moment(billingDate).isSame(createdAt, "day") && (
                        <Typography fontSize="0.8rem" color="gray">
                          {calFns.bsFullDate(createdAt)}
                        </Typography>
                      )}
                    </Box>
                  ),
                  sortable: true,
                  sortFunction: (a, b) =>
                    new Date(a.billingDate) > new Date(b.billingDate) ? 1 : -1
                },
                {
                  key: "services",
                  label: "Services",
                  formatter: ({ services }) => <Typography>{services}</Typography>
                },
                {
                  key: "enteredBy",
                  label: "Billed by",
                  formatter: ({ enteredBy }) => <Typography>{enteredBy}</Typography>
                },
                {
                  key: "grossAmount",
                  label: "Gross Total",
                  formatter: ({ grossAmount }) => <Typography>{grossAmount}</Typography>
                },
                {
                  key: "discount",
                  label: "Discount",
                  formatter: ({ discount }) => <Typography ml="20px">{discount}</Typography>
                },
                {
                  key: "registrationNo",
                  label: "Registration No",
                  formatter: ({ registrationNo }) => <Typography>{registrationNo}</Typography>
                },
                {
                  key: "creditNoteFor",
                  label: "CN For",
                  formatter: ({ billInfo }) => (
                    <Typography>
                      {billInfo.type === DocumentTypes.CREDITNOTE
                        ? billInfo.related?.billNumber
                        : ""}
                    </Typography>
                  )
                },
                {
                  key: "paymentMethod",
                  sortable: true,
                  segmentable: true,
                  segmentBy: (row) => row.paymentMethod,
                  label: "Payment Method",
                  formatter: ({ paymentMethod }) => <Typography>{paymentMethod}</Typography>
                },
                {
                  key: "admissionDate",
                  label: "Admission Date",
                  formatter: ({ admissionDate }) => <Typography>{admissionDate || ""}</Typography>
                },
                {
                  key: "dischargeDate",
                  label: "Discharge Date",
                  formatter: ({ dischargeDate }) => <Typography>{dischargeDate || ""}</Typography>
                },
                {
                  key: "bed",
                  label: "Bed",
                  formatter: ({ bedName }) => <Typography>{bedName}</Typography>
                },
                {
                  key: "paidAmount",
                  label: tl("reports.paidAmount"),
                  formatter: ({ paidAmount }) => (
                    <Typography>{paidAmount ? rupeeDisplay(paidAmount || 0) : 0}</Typography>
                  )
                },
                {
                  key: "dueAmount",
                  label: tl("reports.dueAmount"),
                  formatter: ({ paidAmount, totalAmount }) => {
                    const dueAmount = totalAmount - (paidAmount || 0);
                    return <Typography>{dueAmount ? rupeeDisplay(dueAmount) : 0}</Typography>;
                  }
                },
                {
                  key: "totalAmount",
                  label: tl("reports.total"),
                  formatter: ({ totalAmount }) => (
                    <Typography>{totalAmount ? rupeeDisplay(totalAmount) : ""}</Typography>
                  )
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(acc) => (
                <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                  <Box display="flex" flexGrow={1} padding="10px 20px">
                    {acc.segment || "N/A"}
                  </Box>
                  <Box flexBasis="200px" pt="10px" textAlign="right">
                    {rupeeDisplay(
                      Math.floor(acc.rows.reduce((prev, cur) => prev + cur.grossAmount, 0) || 0)
                    )}
                  </Box>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            "Sales Report",
                            formatHeaderForExcel(docColumns()),
                            formatDataForExcel(getProcessedData(), docRowProcessor)
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        reportsApi
                          .getRevenueTally(filters)
                          .then((response) => {
                            downloadXML(
                              `${t("reports.salesReport")}-${
                                calFns.convertADtoBS(filters.from).formatted
                              }-${calFns.convertADtoBS(filters.until).formatted}`,
                              response.data
                            );
                            actions.addNotification(response.status);
                          })
                          .catch(() => actions.addNotification(500))
                      }
                    >
                      {tl("reports.tally")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
          <Box className="salesReportTotalBarStyle">
            <Box display="flex" paddingLeft="20px" className="totalTxt">
              {t("reports.total")}
            </Box>
            <Typography className="totalValueCell">
              {rupeeDisplay(Number.parseFloat(total.paid).toFixed(2) || 0)}
            </Typography>
            <Typography className="totalValueCell">
              {rupeeDisplay(totalDueAmount.toFixed(2) || 0)}
            </Typography>
            <Typography className="totalValueCell">
              {rupeeDisplay(Number.parseFloat(total.amount).toFixed(2) || 0)}
            </Typography>
          </Box>
        </Box>
        {selectedBill && (
          <BillShow
            billId={selectedBill}
            handleViewClose={() => setSelectedBill(null)}
            hideBillingActions
          />
        )}
        {showClientInfoPanel &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={selectedClientId}
              mode="edit"
              onCancel={() => {
                setClientEditMode(false);
              }}
              stayOnCurrentPage
            />
          ) : (
            <ClientInfo
              id={selectedClientId}
              handleViewClose={clientInfoHandle}
              stayOnCurrentPage
              setEditMode={setClientEditMode}
            />
          ))}
      </Box>
    </Can>
  );
};

export default connect(null, (dispatch) => ({
  actions: {
    navigateTo: (url) => dispatch(push(url)),
    addNotification: (statusCode) => {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: statusCode === 200 ? "success" : "error",
          message:
            statusCode === 200 ? tl("reports.downloadSuccess") : tl("reports.downloadFailure"),
          autoTimeout: true
        })
      );
    }
  }
}))(SalesReport);
