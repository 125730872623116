import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { round } from "mathjs";
import { useDispatch } from "react-redux";
import Filters from "./Filters";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { getStockCashFlowReports } from "../../api/reports";
import { downloadExcel } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import { tl, t } from "../../components/translate";
import styles from "./Reports.module.css";
import Can from "../Policy/Can";
import { notificationAdd } from "../../actions/notification";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import {
  getDefaultKeyValuesColumns,
  stockCashFlowDefaultCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const or = (val) => val || "";

const docColumns = () => [
  t("reports.stock.productName"),
  t("reports.batchId"),
  t("dateBS"),
  t("dateAD"),
  t("reports.invoiceNumber"),
  t("reports.transactionType"),
  t("reports.quantity"),
  t("reports.salesAmount"),
  t("reports.discount"),
  t("reports.netSalesAmount"),
  t("reports.costOfSales"),
  t("reports.profitLoss")
];

const docRowProcessor = ({
  productName,
  batchId,
  date,
  billNumber,
  transactionType,
  quantity,
  salesAmt,
  discountAmt,
  netSalesAmt,
  costOfSales,
  profit,
  // eslint-disable-next-line camelcase
  __meta__row_type
}) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return undefined;
  return [
    productName,
    batchId,
    calFns.bsShortDate(date),
    calFns.adShortDate(date),
    or(billNumber),
    transactionType,
    quantity,
    salesAmt || 0,
    discountAmt || 0,
    netSalesAmt || 0,
    costOfSales || 0,
    profit || 0
  ];
};

const StockCashFlowReport = (): JSX.Element => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(stockCashFlowDefaultCols)
  );
  const [open, setOpen] = React.useState(false);
  const { isRnWebView } = useIsReactNativeWebView();

  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    stockProductCategory: null,
    productItems: [],
    invoiceNumber: null
    // includeInternalUseItems: true
  });

  const applyFilters = (filter) => {
    setFilters(filter);
  };

  useEffect(() => {
    (async () => {
      const {
        from,
        until,
        stockProductCategory,
        productItems,
        invoiceNumber
        // includeInternalUseItems
      } = filters;

      setIsRequestLoading(true);

      try {
        const apiData = await getStockCashFlowReports({
          from,
          until,
          productCategory: stockProductCategory,
          productItems,
          invoiceNumber
        });

        setData(apiData);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to fetch data.",
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [filters]);

  return (
    <Can policyAccessKey="report:stockCashFlowReport">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1300px" : "auto"}>
          <Box m={4}>
            <Filters
              filters={filters}
              onSetFilters={(filter) => applyFilters(filter)}
              isRequestLoading={isRequestLoading}
            />
          </Box>
          <Box
            className={["stockCashFlowReportList", styles.stockCashFlowReportListStyles].join(" ")}
          >
            <List
              automation="stockCashFlowList"
              data={data}
              rowHeight={50}
              activeRow={1}
              adjustHeightToContents
              defaultSortColumn="productName"
              defaultSortOrder={-1}
              columns={[
                {
                  key: "productName",
                  label: tl("reports.stock.productName"),
                  segmentable: true,
                  segmentBy: ({ productName }) => productName,
                  sortable: true,
                  formatter: ({ productName }) => <Typography>{productName}</Typography>
                },
                {
                  key: "batchId",
                  label: tl("reports.batchId"),
                  sortable: true,
                  formatter: ({ batchId }) => <Typography>{batchId}</Typography>
                },
                {
                  key: "date",
                  label: tl("reports.date"),
                  sortable: true,
                  formatter: ({ date }) => <Typography>{calFns.bsFullDate(date)}</Typography>
                },
                {
                  key: "invoiceNumber",
                  label: tl("reports.invoiceNumber"),
                  sortable: true,
                  formatter: ({ billNumber }) => <Typography>{billNumber}</Typography>
                },
                {
                  key: "transactionType",
                  label: tl("reports.transactionType"),
                  sortable: true,
                  formatter: ({ transactionType }) => (
                    <Typography>{tl(`stock.${transactionType}`)}</Typography>
                  )
                },
                {
                  key: "quantity",
                  label: tl("reports.quantity"),
                  sortable: true,
                  formatter: ({ quantity }) => <Typography>{quantity}</Typography>
                },
                {
                  key: "unit",
                  label: tl("reports.unit"),
                  sortable: true,
                  formatter: ({ unit }) => <Typography>{unit}</Typography>
                },
                {
                  key: "salesAmount",
                  label: tl("reports.salesAmount"),
                  sortable: true,
                  formatter: ({ salesAmt }) => <Typography>{round(salesAmt || 0, 2)}</Typography>
                },
                {
                  key: "discount",
                  label: tl("reports.discount"),
                  sortable: true,
                  formatter: ({ discountAmt }) => (
                    <Typography>{round(discountAmt || 0, 2)}</Typography>
                  )
                },
                {
                  key: "netSalesAmount",
                  label: tl("reports.netSalesAmount"),
                  sortable: true,
                  formatter: ({ netSalesAmt }) => (
                    <Typography>{round(netSalesAmt || 0, 2)}</Typography>
                  )
                },
                {
                  key: "costOfSales",
                  label: tl("reports.costOfSales"),
                  sortable: true,
                  formatter: ({ costOfSales }) => (
                    <Typography>{round(costOfSales || 0, 2)}</Typography>
                  )
                },
                {
                  key: "profitLoss",
                  label: tl("reports.profitLoss"),
                  sortable: true,
                  formatter: ({ profit }) => <Typography>{round(profit || 0, 2)}</Typography>
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(acc) => (
                <Box style={{ background: "#e6e6e6" }} pr="30px" display="flex" flexGrow={1}>
                  <Box display="flex" flexGrow={1}>
                    <Typography>
                      <Box
                        component="span"
                        flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight={500}
                      >
                        {acc.segment || "N/A"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box flexBasis="200px" pt="4px" textAlign="right">
                    <Typography>
                      {rupeeDisplay(
                        round(acc.rows.reduce((ac, cur) => ac + cur.profit, 0) || 0, 2)
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            t("reports.stockCashFlowReport", "en"),
                            formatHeaderForExcel(docColumns()),
                            formatDataForExcel(getProcessedData(), docRowProcessor)
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              requiredColumns={["productName"]}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
            <Box className="totalBar">
              <Box display="flex" paddingLeft="20px" flexGrow={0.8}>
                {t("reports.totalProfitLoss")}
              </Box>
              <Box display="flex" flexBasis="230px" paddingRight="32px" justifyContent="flex-end">
                <Typography>
                  {rupeeDisplay(
                    round(
                      data.reduce((acc, cur) => acc + cur.profit, 0),
                      2
                    )
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Can>
  );
};

export default StockCashFlowReport;
