import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Tooltip, Typography } from "@mui/material";
import EditableTable, {
  EditableTableView,
  TableState
} from "../../../../components/EditableTable/EditableTable";
import useCan from "../../../../hooks/useCan";
import { useAppSelector } from "../../../../store/hooks";
import OkhatiDialog from "../../../../components/Dialog/Dialog";

interface Props {
  onSave: (v) => void;
  initValue: TableState;
  isDialog?: boolean;
  setShowTemplateEditor?: () => void;
}

const Culture = ({
  onSave,
  initValue,
  isDialog = true,
  setShowTemplateEditor
}: Props): JSX.Element => {
  const isSuperAdmin = useCan("deny", { superAdminPass: true });
  const draftGroup = useAppSelector((state) => state.labSettings.draftGroup);
  const masterGroupData = useAppSelector((state) => state.labSettings.masterGroup);
  const masterCultureTemplate = isSuperAdmin
    ? draftGroup?.additionalData?.cultureTemplate
    : masterGroupData?.additionalData?.cultureTemplate;
  const [isEditingCulture, setIsEditingCulture] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [cultureState, setCultureState] = React.useState<TableState>();

  if (isDialog) {
    return (
      <div>
        <Typography
          onClick={handleClickOpen}
          style={{ padding: "4px 8px", width: "240px" }}
          role="button"
        >
          Interpretation Template
        </Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">Interpretation Template</DialogTitle>
          <Box p="16px">
            <EditableTable
              hideLabel={false}
              maxColCount={10}
              onChange={(v) => setCultureState(v)}
              initValue={initValue}
              hideControls={false}
            />
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClose();
                onSave(cultureState);
              }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return (
    <>
      <Box display="flex" justifyContent="flex-end" gap={2}>
        {isSuperAdmin && (
          <Button onClick={setShowTemplateEditor}>Add/Edit Template Master Data</Button>
        )}
        {masterCultureTemplate && (
          <Tooltip arrow title="Preview and apply predefined template from master data">
            <Button
              onClick={() => {
                setIsPreviewOpen(true);
              }}
            >
              Preview Culture Template
            </Button>
          </Tooltip>
        )}
        {!isEditingCulture && (
          <Tooltip arrow title="Edit current template">
            <Button variant="outlined" onClick={() => setIsEditingCulture(true)}>
              Edit template
            </Button>
          </Tooltip>
        )}
      </Box>
      {isEditingCulture ? (
        <EditableTable
          maxColCount={10}
          onChange={(v) => onSave(v)}
          initValue={initValue}
          hideControls={false}
        />
      ) : (
        <EditableTableView tableState={initValue} />
      )}
      <OkhatiDialog
        title="Preview and appy template from masterdata"
        description={<EditableTableView isForPreview tableState={masterCultureTemplate} />}
        next={async () => {
          setIsEditingCulture(false);
          setTimeout(() => {
            onSave(masterCultureTemplate);
            setIsEditingCulture(true);
          }, 0);
          setIsPreviewOpen(false);
        }}
        nextButtonText="Apply"
        readMode={false}
        cancel={() => {
          setIsPreviewOpen(false);
        }}
        open={isPreviewOpen}
      />
    </>
  );
};

export default Culture;
