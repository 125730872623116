import React from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import getSummaryReports from "../../../api/summaryReport";
import Filters from "../Filters";
import * as calFns from "../../../components/Calendar/functions/calendarFunctions";
import SummaryReportTable from "./SummaryReportTable";

function ServiceCenterSummaryReport(props) {
  const { resourceCentreId, rcId } = props;
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [summaryReportData, setSummaryReportData] = React.useState([]);

  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date()
  });

  const [id, setId] = React.useState(null);

  React.useEffect(() => {
    if (resourceCentreId) setId(resourceCentreId);
    if (rcId) setId(rcId);
  }, [resourceCentreId, rcId]);

  React.useEffect(() => {
    setIsRequestLoading(true);
    getSummaryReports({ id, from: filters.from, until: filters.until })
      .then((response) => setSummaryReportData(response))
      .finally(() => setIsRequestLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Box marginTop="32px" marginBottom="40px">
      <Box margin="0px 32px">
        <Box marginTop="32px">
          <Filters
            filters={filters}
            onSetFilters={(newFilter) => setFilters(newFilter)}
            isRequestLoading={isRequestLoading}
          />
        </Box>
      </Box>
      <SummaryReportTable summaryReportData={summaryReportData} />
    </Box>
  );
}

export default connect(
  (state) => ({
    rcId: state.userContext?.resourceCentreId
  }),
  null
)(ServiceCenterSummaryReport);
