import { Box, Button, Typography } from "@mui/material";
import React from "react";
import produce from "immer";
import { useParams } from "react-router";
import ImageCrop from "../../../components/ImageCrop/ImageCrop";
import { customHeaderImagePresignedS3 } from "../../../api/resourceCentre";
import { notificationAdd } from "../../../actions/notification";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resourceCentreActions as actions } from "../../../actions";

const UploadStamp = (): JSX.Element => {
  const [selectedImg, setSelectedImg] = React.useState<string | null>(null);
  const [isCropPopupOpen, setIsCropPopupOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const { resourceCentres } = useAppSelector((state: RootState) => state.resources);
  const rcId = Number(params.id);
  const resourceCentre: ResourceCentre = resourceCentres.find((item) => item.id === rcId);

  const { medicalSettings } = resourceCentre.settings;

  const onImageChange = (e) => {
    if (!e?.target?.files?.length) {
      return;
    }
    const file = e.target.files[0];
    const lastDotIndex = file.name.lastIndexOf(".");
    const extension = file.name.substring(lastDotIndex + 1);

    if (file.size >= 200000 || !["jpg", "jpeg", "png", "gif"].includes(extension.toLowerCase())) {
      // show error dialog if file size exceeds 200KB
      // eslint-disable-next-line no-param-reassign
      e.target.value = "";
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "File type not valid or size is greater than 200KB.",
          autoTimeout: true
        })
      );
      return;
    }

    setSelectedImg(URL.createObjectURL(file));
    setIsCropPopupOpen(true);
  };

  return (
    <Box mt={2}>
      <Typography sx={{ fontWeight: 600 }} gutterBottom>
        Upload Digital Stamp
      </Typography>
      <Box>
        {medicalSettings.stampImage && (
          <img
            src={medicalSettings.stampImage}
            alt="stamp"
            style={{
              objectFit: "cover",
              maxWidth: "100%"
            }}
          />
        )}
      </Box>
      <label htmlFor="stampUploader">
        <input
          className="imageUpload"
          data-testmation="uploadBannerInput"
          type="file"
          accept=".jpg,.jpeg,.gif,.png"
          name="stampUploader"
          onChange={onImageChange}
          id="stampUploader"
          style={{
            display: "none"
          }}
        />
        <Button variant="contained" component="span">
          Upload Stamp
        </Button>
      </label>
      {medicalSettings.stampImage && (
        <Button
          variant="contained"
          sx={{ backgroundColor: "red", marginLeft: 1 }}
          onClick={() => {
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.medicalSettings.stampImage = "";
            });
            dispatch(actions.putResourceCentre(newRc));
          }}
        >
          Remove Stamp
        </Button>
      )}
      <ImageCrop
        image={selectedImg}
        dynamicRatio={false}
        defaultRatio={{ x: 1, y: 1 }}
        isOpen={isCropPopupOpen}
        onClose={() => setIsCropPopupOpen(false)}
        onSave={async (img) => {
          try {
            const data = await customHeaderImagePresignedS3(img);
            if (!data) {
              return;
            }
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.medicalSettings.stampImage = data;
            });
            dispatch(actions.putResourceCentre(newRc));
          } catch (err) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: "Failed to upload custom header",
                autoTimeout: true
              })
            );
          }
        }}
      />
    </Box>
  );
};

export default UploadStamp;
